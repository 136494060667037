import React, { useEffect, useState } from 'react';
import "./sdpoverview.scss"



const SdpOverview = ({ shelfData}) => {
  const [loading, setLoading] = useState(true);
//   console.log("store" ,shelfData.StoreDescription)


  useEffect(() => {
    // Assume some logic to fetch shelf details
    // Once the data is fetched, set loading to false
    // setLoading(false);

    // For demonstration purposes, let's simulate loading delay
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clear the timeout to avoid memory leaks if the component unmounts
    return () => clearTimeout(delay);
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

 

    return (
    <div className='sdp_overview_container'>
      <div className="sdpshopOverviewParent">
                <div className="sdpshopOverview">Store overview</div>
                <div className="sdpcomeAndStayContainer">
                      <p className="sdpspaciousAndBright">{shelfData.StoreDescription}</p>
                   
                </div>
               
          </div>

    </div>
  )
}

export default SdpOverview
