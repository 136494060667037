import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './user_form.scss';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';

const categories = [
  { value: 'Fashion & Accessories', label: 'Fashion & Accessories' },
  { value: 'Health & beauty', label: 'Health & beauty' },
  { value: 'Kids', label: 'Kids' },
  { value: 'DIY & Hardware', label: 'DIY & Hardware' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Furniture & Interiors', label: 'Furniture & Interiors' },
  { value: 'Gardening', label: 'Gardening' }
];
//['FMCG', 'Fashion & Accessories', 'Health & beauty' , 'Electronics', 'DIY & Hardware', 'Furniture & Interiors', 'Gardening'];
const UserForm = () => {
  const [name, setName] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({});
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  const [brandName, setBrandName] = useState(''); // For "Rent a shelf"
  const [storeLocation, setStoreLocation] = useState(''); // For "List your store"
  const [footfall, setFootfall] = useState(''); // For "List your store"

  const validateForm = () => {
    let formErrors = {};
    
    if (!name.trim()) formErrors.name = 'Name is required';
    if (!email.trim()) formErrors.email = 'Email is required';
    if (!location.trim()) formErrors.location = 'Location is required';
    if (!role) formErrors.role = 'Role is required';
    if (selectedCategories.length === 0) formErrors.category = 'At least one category must be selected';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    const selectedCategoryValues = selectedCategories.map(category => category.value);

    const mutation = `
      mutation {
        setUserNeed(
          name: "${name}",
          location: "${location}",
          category: "${selectedCategoryValues.join(', ')}",
          email: "${email}"
        ) {
          usersNeed_id
        }
      }
    `;

    try {
      const response = await axios.post(graphqlEndpoint, {
        query: mutation
      });

      if (response.data.errors) {
        throw new Error('An error occurred');
      }

      toast.success('Your query is submitted. We will reach out soon.');
    
    // Close the form after 5 seconds
    setTimeout(() => {
      handleCloseForm();  // This function should hide/close the form
    }, 2000);  // 5000 milliseconds = 5 seconds
  } catch (error) {
    toast.error('Error submitting your query. Please try again.');
    console.error('Error:', error);
  } 
  }
  const handleCloseForm = () => {
    setRedirectToHome(true); // Trigger redirection to home
  };

  // Conditionally render Navigate to redirect to home page
  if (redirectToHome) {
    return <Navigate to="/" />;
  }


  return (
    <div className='user_form_container_navbar'>
      <ToastContainer />
      <div className="user_form_section">
      <div className="logo_img_popup">
        <Link  to ="/"><img className="logo_img_popup_img" src="https://fixedimages.s3.amazonaws.com/Logo.jpg" alt="Logo" /></Link>
      </div>
        <div className="Sign_up_for_early_access">Sign up for early access!</div>
      <div className="headline_user_details">
        Hey there! We're in private beta and have just launched the FMCG category in London stores. If this isn't what you were looking for, please register your interest, and we'd be happy to help you explore offline distribution options.
      </div>

      <form onSubmit={handleSubmit}>
        <div className="user_input_section">

            <div className="user_name_email_collection">

          <div className="user_name_collection">

            <div className="tag_line_user">Name</div>
            <input
              type="text"
              className="user_input"
              placeholder="ex: Robert"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <span className="error_message">{errors.name}</span>}
          </div>

          <div className="email_collection">
            <div className="tag_line_user">Email</div>
            <input
              type="email"
              className="user_input"
              placeholder="ex: abc@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <span className="error_message">{errors.email}</span>}
          </div>

          </div>

          <div className="user_location_about_collection">

          <div className="location_collection">
            <div className="location_user">Location</div>
            <input
              type="text"
              className="user_input_location"
              placeholder="ex: London"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
            {errors.location && <span className="error_message">{errors.location}</span>}
          </div>

          <div className="role_of_request">
        <div className="role_of_user">About you</div>
        <select
          id="location"
           className="user_input_role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option  value="">Select your role</option>
          <option value="Rent a shelf">Rent a shelf</option>
          <option value="List your store">List your store</option>
        </select>
        {errors.role && <span className="error_message">{errors.role}</span>}
      </div>

      </div>
      {/* Conditionally render based on role */}
      {role === 'Rent a shelf' && (
        <div className="additional_section">
          <div className='additional_details'>About your Brand</div>
          <textarea
            rows= {5} // Controls the initial number of visible rows
          
            className="user_input_additional"
            placeholder="Brand details"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
          />
          {/* Optionally display errors */}
        </div>
      )}

      {role === 'List your store' && (
        <div className="additional_section">
          <div className='additional_details'>Store Details</div>
          <textarea
    rows= {5} // Controls the initial number of visible rows
    className="user_input_additional"
    placeholder="Enter your store details"
    value={storeLocation}
    onChange={(e) => setStoreLocation(e.target.value)}
  />

          
          {/* Optionally display errors */}
        </div>
      )}


        












          <div className="category_collection">
            <div className="tag_line_user">Category</div>
            <Select
              isMulti
              options={categories}
              value={selectedCategories}
              onChange={handleCategoryChange}
              className="category_select"
              placeholder="Select categories"
              
            />
            {errors.category && <span className="error_message">{errors.category}</span>}
          </div>
          <button type="submit" className="submit_button">Submit</button>
        </div>
      </form>

      </div>
    </div>
  );
};

export default UserForm;
