import React, { useEffect , useState } from 'react';
import "./Form1.scss" ;
import axios from 'axios';
import "./Form1.scss"
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form13 = (props) => {
          const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
          const authToken = props.authToken; // Assuming you have an authToken prop

  

                      // handle condition for next and save
	                    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
	                    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
                      const handleSaveClick = async () => {
		                  try {
		                  setIsSaveButtonClicked(true);
		                  toast.success('Data saved successfully!');
		                  setIsNextButtonDisabled(false); 
		                  } catch (error) {
		                  console.error('Save error:', error);   } };
                      const handleNextClick = () => {
                      if (!isSaveButtonClicked) {
                      toast.error('Please click "Save" before proceeding to "Next".');
                      } else {
                      toast.info('Proceeding to the next step.');
                      } } ;
 


                                                                const [formData9, setFormData9] = useState({
                                                                  input1: { value1: '09:00', value2: '22:00' },
                                                                  input2: { value1: '09:00', value2: '22:00' },
                                                                  input3: { value1: '09:00', value2: '22:00' },
                                                                  input4: { value1: '09:00', value2: '22:00' },
                                                                  input5: { value1: '09:00', value2: '22:00' },
                                                                  input6: { value1: '09:00', value2: '22:00' },
                                                                  input7: { value1: '09:00', value2: '22:00' },
                                                                });
        
                                                          const handleInputChange = (inputKey, inputNumber, value) => {
                                                          setFormData9((prevData) => ({
                                                          ...prevData,
                                                          [inputKey]: {
                                                          ...prevData[inputKey],
                                                          [`value${inputNumber}`]: value,
                                                            }, }));};
      
      const [selectedTime, setSelectedTime] = useState('');
      const times = ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00'];
      const handleTimeChange = (event) => {
      setSelectedTime(event.target.value);
      };	


                     const processForm = () => {
                     const storeTiming = Object.entries(formData9).map(([key, values]) => ({
                     key,
                     value: `${values.value1} ${values.value2}`,
                     }));
                     const storeTimings = storeTiming.map(pair => `${pair.key}: ${pair.value}`).join(', ');
                     console.log(storeTimings);
                     return storeTimings;
                     };
                     const [formData8, setFormData8] = useState({
                     id: props.storeId  || "",
                     storeTimings: '',
                     });

                                                 useEffect(() => {
                                                 setFormData8((prevFormData8) => ({ ...prevFormData8, id: props.storeId || '' }));
                                                 }, [props.storeId]);
                                                 const handleSubmit9 = async (e) => {
                                                 e.preventDefault();
                                                 try {
                                                 const processedFormData = processForm();
                                                 const response = await axios.post(graphqlEndpoint, {
                                                 query: `
                                                 mutation storeTiming(
                                                 $id: Long!,
                                                 $storeTimings: String!,
                                                ) {
                                                 storeTiming(
                                                 id: $id,
                                                 storeTimings: $storeTimings,  ) }`,
                                                 variables: {
                                                 id: parseInt(formData8.id), // Assuming id is a Long on the backend
                                                 storeTimings: processedFormData,
                                                    }, });
          setFormData9({
            input1: { value1: '', value2: '' },
            input2: { value1: '', value2: '' },
            input3: { value1: '', value2: '' },
            input4: { value1: '', value2: '' },
            input5: { value1: '', value2: '' },
            input6: { value1: '', value2: '' },
            input7: { value1: '', value2: '' },
          });
          // Handle the response data
          const isStoreTimingTrue = response.data.data.storeTiming;
    //      console.log(response);
     //     console.log(isStoreTimingTrue)
          
       
 //       console.log(localStorage)

          // Reset the form after successful submission
          setFormData8({
            id: props.storeId  || "",
            storeTimings: '',
          });
        } catch (error) {
          // Handle errors
          console.error(error);
        }
      };
     
     
    return (
        <div className="form13">
           <div className="form1thirdblock">
                    
                    
              <div className="thirddata">
              <div className="fourthdatabox">
            <div className="storetitle">Store Timing</div>
            <div className="storetitledata">Tell us about your Store. These specifics will help us figure out the best clients for you. </div>
            </div></div>

  
                    


            <div className="days">

              
                <div className="day">
                <div className="dayname">Monday</div>
                
                <div className="open_close_time">
                <div className="opentiming_input">
                <div className="opentime" > 
                
              <select
  id="opentiming"
  name="input1"
  placeholder='00:00'
  value={formData9.input1.value1}
  onChange={(e) => handleInputChange('input1', 1, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
              
              
                </div>
                </div>
                <div className="closetiming_input">  
        
                <div className="closetime"> 	
  
  <select
    id="closetiming"
  name="input1"
  placeholder='  23:59'
  value={formData9.input1.value2}
  onChange={(e) => handleInputChange('input1', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>


               </div>
                </div>
                </div>
                </div>





                <div className="day">
                <div className="dayname">Tuesday</div>
                <div className="open_close_time">
                <div className="opentiming_input">
                <div className="opentime"> <select
  id="opentiming"
  name="input2"
  placeholder='00:00'
  value={formData9.input2.value1}
  onChange={(e) => handleInputChange('input2', 1, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          
          </div>
                </div>
                <div className="closetiming_input">
                <div className="closetime"> 
          
          <select
    id="closetiming"
  name="input2"
 // placeholder='  23:59'
  value={formData9.input2.value2}
  onChange={(e) => handleInputChange('input2', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          
          
          
          </div>
                </div></div>
                </div>

                <div className="day">
                <div className="dayname">Wednesday</div>
                <div className="open_close_time">
                <div className="opentiming_input">
                <div className="opentime">
            <select
  id="opentiming"
  name="input3"
  placeholder='00:00'
  value={formData9.input3.value1}
  onChange={(e) => handleInputChange('input3', 1, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          
          
          </div>
                </div>
                <div className="closetiming_input">
                <div className="closetime"> 
          
          <select
    id="closetiming"
  name="input3"
 // placeholder='  23:59'
  value={formData9.input3.value2}
  onChange={(e) => handleInputChange('input3', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          
          
          
          </div>
                </div></div>
               </div>



                <div className="day">
                <div className="dayname">Thursday</div>
                <div className="open_close_time">
                <div className="opentiming_input">
                <div className="opentime">
            <select
  id="opentiming"
  name="input4"
  placeholder='00:00'
  value={formData9.input4.value1}
  onChange={(e) => handleInputChange('input4', 1, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          
          
          </div>
                </div>
                <div className="closetiming_input">
                <div className="closetime"> 
          
          
          <select
   id="closetiming"
  name="input4"
 // placeholder='  23:59'
  value={formData9.input4.value2}
  onChange={(e) => handleInputChange('input4', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          


          
                </div>   
          
            </div>
                </div></div>






                <div className="day">
                <div className="dayname">Friday</div>
                <div className="open_close_time">
                <div className="opentiming_input">
                <div className="opentime">
          
          <select
  id="opentiming"
  name="input5"
  placeholder='00:00'
  value={formData9.input5.value1}
  onChange={(e) => handleInputChange('input5', 1, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          </div>
                </div><div className="closetiming_input">
                <div className="closetime"> 
          <select
    id="closetiming"
  name="input5"
 // placeholder='  23:59'
  value={formData9.input5.value2}
  onChange={(e) => handleInputChange('input5', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
          </div>  </div>
                </div></div>




                <div className="day">
                <div className="dayname">Saturday</div>
                <div className="open_close_time">


                <div className="opentiming_input">
                <div className="opentime">  
                      <select
          id="opentiming"
          name="input6"
          placeholder='00:00'
          value={formData9.input6.value1}
          onChange={(e) => handleInputChange('input6', 1, e.target.value)}
        
        >
        <option value=""> Select the Time</option>
            {times.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
                      </select>
                     </div>
                </div>
                
                
                <div className="closetiming_input">
                <div className="closetime">
          <select
    id="closetiming"
  name="input6"
 // placeholder='  23:59'
  value={formData9.input6.value2}
  onChange={(e) => handleInputChange('input6', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>
                </div>
                </div>


                </div>
                
                </div>




                <div className="day">
                <div className="dayname">Sunday</div>
                <div className="open_close_time">
                <div className="opentiming_input">
                <div className="opentime">  
                  <select
                     id="opentiming"
                     name="input7"
                     placeholder='00:00'
                     value={formData9.input7.value1}
                     onChange={(e) => handleInputChange('input7', 1, e.target.value)}
                    >
               <option value=""> Select the Time</option>
                   {times.map((time) => (
              <option key={time} value={time}>
                {time}
              </option>
            ))}
        </select>
                </div>
                </div><div className="closetiming_input">
                <div className="closetime">
          <select
    id="closetiming"
  name="input7"
 // placeholder='  23:59'
  value={formData9.input7.value2}
  onChange={(e) => handleInputChange('input7', 2, e.target.value)}

>
<option value=""> Select the Time</option>
    {times.map((time) => (
      <option key={time} value={time}>
        {time}
      </option>
    ))}
</select>   
                </div>
                </div>
                </div></div>
                 </div>
    


                      <form onSubmit={handleSubmit9}>
                      <div className="linksummit">
                     <button type="submit" id="button2" onClick={handleSaveClick}>
                      Save
                     </button>
                     <Link className={`linkform_timing ${isNextButtonDisabled ? 'disabled' : ''}`} to={isSaveButtonClicked ? "Form14" : ""} onClick={handleNextClick}>
                      Next
                    </Link>
                    </div>



    
        </form>
    
        
            </div>
          </div>
          )
}

export default Form13 ;