import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const RetailNotification = () => {

  const [isActive, setIsActive] = useState(true);

  const handleToggle = () => {
    setIsActive(!isActive);
    const message = isActive ? 'Inactive Notification' : 'Active Notification';
    toast(message);
  };

  return (
    <div  className='notification_container_retail'>
        <div className="retail_notification_sections">
<div className="notification_head">Notification</div>
<div className="notification_type">Booking/Cancellations</div>
<div className="notification_mediam">Email Notification</div>
<div className="notification_status">
      <img
      className='toggle_notification'
        src={isActive ? "./ecomm_status_not.svg" : "./close_notifi.svg"}
        alt={isActive ? "Active" : "Inactive"}
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
      />
      <span>{isActive ? "Active" : "Inactive"}</span>
 
    </div>
    
    {/*}
<div className="notification_edits">
<img  className = "notification_edit" src ="./delete_ecomm_not.svg" alt = "" />
<img className = "notification_edit" src ="./ecomm_edit_not.svg" alt = "" />
</div>
{*/}
        </div>
    
    </div>
  )
}

export default RetailNotification
