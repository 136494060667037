import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Synergydatatile4= () => {
  const prices = ['£20-£80', '£100-£200','£200-£400','£400-£500','£500',];
  const [selectedOptionsprice, setSelectedOptionsprice] = useState([]);

  const handleCheckboxChangeprice = (price) => {
    if (selectedOptionsprice.includes(price)) {
      setSelectedOptionsprice(selectedOptionsprice.filter((selected) => selected !== price));
    } else {
      setSelectedOptionsprice([...selectedOptionsprice, price]);
    }
  };
 sessionStorage.setItem('selectedOptionsprice',selectedOptionsprice)
  // <p>Selected Options: {selectedOptionsprice.join(', ')}</p>
  return (
    <div className='synergydatatile1'>
       <div className="synergydataleft">

        
<div className="logo_summary">
<div className="synergy_icon_left_top">
<Link to="/">
<img src="/synergy_logo.svg" alt="Synergy Logo" />
</Link>
</div>
<div className="synergy_text">Create the most affordable omnichannel experience for your product.</div>
</div>
<div className="synergy_insight_section">
<div className="synergy_insight_data">
<div className="side_img_synergy_insight">
            <div className="synergy_insight_percentage">54%</div>
            <div className="img_animation_section">
         <img className="animated-image_insight top-right"  src="/icon_insight_1.svg" alt=""  />
         <img src="/icon_insight_2" alt="" className="animated-image_insight top-right" />
         <img src="/icon_insight_1" alt="" className="animated-image_insight top-right" />
         </div>
        </div> 
  <div className="synergy_insight_data_text">users are likely to look at a product in-store and buy online. (Credits : Shopify)</div>
  <div className="synergy_insight_controller">
    <div className="synergy_insight_controller_left"></div>
    <div className="synergy_insight_progressbar"><img src ="/progessbar_4.svg" alt ="" /></div>
    <div className="synergy_insight_controller_right"></div>
    </div>
  </div>

</div>



</div>
      <div className="synergydataright">
      <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>


        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">What is the price range of your product ?</div>
     

      <div className='fourtsynergyoption'>
 
      {prices.map((price, index) => (
        <label
          key={index}
          className={`checkbox-container ${selectedOptionsprice.includes(price) ? 'selected' : ''}`}
        >
          <input
            type="checkbox"
            checked={selectedOptionsprice.includes(price)}
            onChange={() => handleCheckboxChangeprice(price)}
          />
          {price}
        </label>
      ))}
     
    </div>


    </div>

    </div>
  )
}

export default Synergydatatile4
