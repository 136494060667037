import React from 'react'
import "./Solve.css"
import { data } from "./solveData"

const Solve = () => {
  return (
            <section className="solve-container">
                  <div className="solve-title">
                        <div className="solve-body">
                              <p className="solve-text-main">
                                    <span className="solve-text-highlight">Solving</span>
                                    <span> the</span>
                              </p>
                              <p className="solve-text">industrial problems</p>
                        </div>
                        <div className="solve-sub-title"> 
                              We believe the diminishing in-store profitability is a major factor in the struggles of high
                              street retail performance, and we aspire to make a bold impact.
                        </div>
                  </div>

                  <div className="problems">
                        {
                              data.map((item) => (
                                    <div className="solve-card" key={item.id}>
                                          <div className="card-number">{`0${String(item.id)}`}</div>
                                          <div className="solve-card-image">
                                                <img src={item.image} alt="" />
                                          </div>
                                          <div className="solve-card-title">
                                                {item.title}
                                          </div>
                                          <div className="solve-card-description">
                                                {item.description}
                                          </div>
                                    </div>
                              ))
                        }
                  </div>

            </section>
      )
}



export default Solve
