import dayjs from "dayjs";
import React, { useState, useRef, useMemo, useEffect } from "react";
import dateRangeStyle from "./dateRangePicker.module.scss";

const Calender2 = ({
  weekStartDay,
  weekSize,
  containerWidth,
  setStartDate,
  setEndDate,
  openState,
  parentRef,
  focusState,
  applyFunc,
  cancelFunc,
}) => {
  const handleApply = () => {
    window.location.reload();
  };

  const [firstDate, setFirstDate] = useState(dayjs());
  const [secondDate, setSecondDate] = useState(dayjs().add(1, "month"));
  const [hoverDate, setHoverDate] = useState(null);
  const [selectedFirstDate, setSelectedFirstDate] = useState(null);
  const [selectedLastDate, setSelectedLastDate] = useState(null);
  const [selectedDaysCount, setSelectedDaysCount] = useState(0);
  const [isSingleMonthView, setIsSingleMonthView] = useState(false);
  const containerRef = useRef(null);

  const getDate = (first, second) => {
    const difference = first + 1 - (second + 1);
    if (difference === 0) {
      return 0;
    } else if (difference > 0) {
      return difference;
    }
    return difference + 7;
  };

  const calendarGridValue = (year, month) => {
    const weekDayStart =
      weekStartDay && !isNaN(parseInt(weekStartDay, 10))
        ? parseInt(weekStartDay, 10)
        : 0;
    const firstDayOfMonth = dayjs().year(year).month(month).startOf("month");
    const firstCalenderDay = firstDayOfMonth.subtract(
      getDate(firstDayOfMonth.day(), weekDayStart),
      "day"
    );

    const dayList = [];
    let loopDate = firstCalenderDay;
    for (let i = 0; i < 42; i++) {
      dayList.push(loopDate);
      loopDate = loopDate.add(1, "day");
    }
    return dayList;
  };

  const handleResize = () => {
    setIsSingleMonthView(window.innerWidth < 768);
  };

  useEffect(() => {
    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changePreviousYear = () => {
    setFirstDate((prev) => prev.subtract(1, "month"));
    setSecondDate((prev) => prev.subtract(1, "month"));
  };

  const changeNextYear = () => {
    setFirstDate((prev) => prev.add(1, "month"));
    setSecondDate((prev) => prev.add(1, "month"));
  };
  const storeCreationDate =  sessionStorage.getItem('store_creation_date');

  // Convert to 'YYYY-MM-DD' format
  const formattedDate_block_after = dayjs(storeCreationDate).format('YYYY-MM-DD');
  const tenDaysFromCreationDate = dayjs(formattedDate_block_after).add(10, "day");

  // Block 10 days from creation date
  const calenderClick = (date, calenderSide) => {
    const today = dayjs();

    // Check if the date is blocked (within the first 10 days from creation date)
    if (date.isBefore(today, "day") || 
        (date.isSame(today, "day") && date.isBefore(tenDaysFromCreationDate, "day"))) {
      return; // Do not allow selecting blocked dates
    }

    if (!date.isSame(firstDate, "Month") && calenderSide === "right") {
      return;
    }
    if (!date.isSame(secondDate, "Month") && calenderSide === "left") {
      return;
    }
    if (selectedLastDate !== null) {
      setSelectedLastDate(null);
      setSelectedFirstDate(date);
      return;
    }
    const count = dayjs(selectedLastDate).diff(selectedFirstDate, 'day') + 1;
    setSelectedDaysCount(count);
    if (selectedFirstDate !== null) {
      if (selectedFirstDate.isSame(date, "day")) {
        return;
      }
      if (selectedFirstDate.isBefore(date)) {
        setSelectedLastDate(date);
      } else {
        setSelectedFirstDate(date);
      }
      return;
    }
    setSelectedFirstDate(date);
  };

  const getColor = (date) => {
    const today = dayjs();

    // Block past dates and the next 10 days from the creation date
    if (date.isBefore(today, "day") || 
        (date.isSame(today, "day") && date.isBefore(tenDaysFromCreationDate, "day"))) {
      return dateRangeStyle.calenderCellDisabled; // Style for past dates
    }
    
    // Block the first 10 days from the creation date
    if (date.isBefore(tenDaysFromCreationDate, "day")) {
      return dateRangeStyle.calenderCellDisabled; // Disable these dates
    }

    if (date.isSame(selectedFirstDate, "day")) {
      return dateRangeStyle.calenderCellStart;
    }
    if (date.isSame(selectedLastDate, "day")) {
      return dateRangeStyle.calenderCellEnd;
    }
    if (selectedFirstDate !== null && selectedLastDate !== null) {
      if (date.isAfter(selectedFirstDate) && date.isBefore(selectedLastDate)) {
        return dateRangeStyle.calenderCellInbetween;
      }
    }
    if (selectedFirstDate !== null && selectedLastDate === null) {
      if (date.isAfter(selectedFirstDate) && date.isBefore(hoverDate)) {
        return dateRangeStyle.calenderCellInbetween;
      }
    }
    return dateRangeStyle.calenderCellNormal;
  };

  const handleClearDates = () => {
    setSelectedFirstDate(null);
    setSelectedLastDate(null);
    setSelectedDaysCount(0);
    sessionStorage.removeItem("daysCount");
    window.location.reload();
  };

  useEffect(() => {
    if (selectedFirstDate !== null && selectedLastDate !== null) {
      const count = dayjs(selectedLastDate).diff(selectedFirstDate, "day") + 1;
      setSelectedDaysCount(count);

      const startDateString = selectedFirstDate.format("YYYY-MM-DD");
      const endDateString = selectedLastDate.format("YYYY-MM-DD");

      sessionStorage.setItem("selectedStartDate", startDateString);
      sessionStorage.setItem("selectedEndDate", endDateString);
      sessionStorage.setItem("daysCount", count.toString());
    }
  }, [selectedFirstDate, selectedLastDate, selectedDaysCount]);

  const getWeekDays = useMemo(() => {
    const startDayOfWeek =
      weekStartDay && !isNaN(parseInt(weekStartDay, 10))
        ? parseInt(weekStartDay, 10)
        : 0;
    const daysOfWeek = [];
    for (let i = startDayOfWeek; i < startDayOfWeek + 7; i++) {
      const dayName = dayjs().day(i % 7).format("dddd");
      daysOfWeek.push(dayName);
    }
    return daysOfWeek;
  }, [weekStartDay]);

  const getCalenderGridFirst = useMemo(() => {
    return calendarGridValue(firstDate.year(), firstDate.month());
  }, [weekStartDay, firstDate]);

  const getCalenderGridSecond = useMemo(() => {
    return calendarGridValue(secondDate.year(), secondDate.month());
  }, [weekStartDay, secondDate]);

  return (
    <div className={dateRangeStyle.calender}>
      <div
        style={{ width: containerWidth }}
        ref={containerRef}
        className={dateRangeStyle.dateRangeContainer}
      >
        <div className={dateRangeStyle.topcalender}>
      <div className={dateRangeStyle.topcalenderheadline}>Book Shelf for {selectedDaysCount} days</div>
          <div className={dateRangeStyle.topcalenderclear} onClick={handleClearDates}>
        Clear Dates
          </div>
        </div>

        <div className={dateRangeStyle.calenderContainer}>
          <div className={dateRangeStyle.rightCalender}>



            <div className={dateRangeStyle.headerCalender}>
              <div className={dateRangeStyle.nextButton}  onClick={changePreviousYear} > {"<"}  </div>
              <div className={dateRangeStyle.yearMonthTitle}> {firstDate.format("MMMM YYYY")} </div>

              {isSingleMonthView && (<div className={dateRangeStyle.nextButton} onClick={changeNextYear}>  {">"} </div> )}

            </div>





            <div className= {dateRangeStyle.dates_day_container}>

            <div className={dateRangeStyle.weekContainer}>
              {getWeekDays.map((weekDay) => (
                <div className={dateRangeStyle.weekCells} key={weekDay}>
                  {weekDay.slice(0, 3)}
                </div>
              ))}
            </div>
         
            <div className={dateRangeStyle.dateContainer}>
              {getCalenderGridFirst.map((dayValue) => (
                <div
                  key={dayValue.toString()}
                  className={`${dateRangeStyle.dayCells} ${getColor(dayValue)} ${
                    !dayValue.isSame(firstDate, "Month") &&
                    dateRangeStyle.calenderCellDisabled
                  }`}
                  onClick={() => calenderClick(dayValue, "right")}
                  onMouseEnter={() => {
                    if (dayValue.isSame(firstDate, "Month")) {
                      setHoverDate(dayValue);
                    }
                  }}
                  onMouseLeave={() => {
                    if (dayValue.isSame(firstDate, "Month")) {
                      setHoverDate(null);
                    }
                  }}
                >
                  {dayValue.format("D")}
                </div>
              ))}
            </div>
            </div>
          </div>

          {!isSingleMonthView && (
          <div className={dateRangeStyle.leftCalender}>
            <div className={dateRangeStyle.headerCalender}>
              <div className={dateRangeStyle.yearMonthTitle}>
                {secondDate.format("MMMM YYYY")}
              </div>
              <div className={dateRangeStyle.nextButton} onClick={changeNextYear}>
                {">"}
              </div>
            </div>
            <div className= {dateRangeStyle.dates_day_container}>
            <div className={dateRangeStyle.weekContainer}>
              {getWeekDays.map((weekDay) => (
                <div className={dateRangeStyle.weekCells} key={weekDay}>
                  {weekDay.slice(0, 3)}
                </div>
              ))}
            </div>
            <div className={dateRangeStyle.dateContainer}>
              {getCalenderGridSecond.map((dayValue) => (
                <div
                  key={dayValue.toString()}
                  className={`${dateRangeStyle.dayCells} ${getColor(dayValue)} ${
                    !dayValue.isSame(secondDate, "Month") &&
                    dateRangeStyle.calenderCellDisabled
                  }`}
                  onClick={() => calenderClick(dayValue, "left")}
                  onMouseEnter={() => {
                    if (dayValue.isSame(secondDate, "Month")) {
                      setHoverDate(dayValue);
                    }
                  }}
                  onMouseLeave={() => {
                    if (dayValue.isSame(secondDate, "Month")) {
                      setHoverDate(null);
                    }
                  }}
                >
                  {dayValue.format("D")}
                </div>
              ))}
            </div>
            </div>


          </div>
          )}
        </div>
        <div className={dateRangeStyle.calenderapply}  onClick = { handleApply} >
         Apply  </div>
      </div>
    </div>
  );
};

export default Calender2;
