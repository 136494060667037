import React from 'react'
import CheckoutPage from '../../components/checkout/CheckoutPage'

const Checkout = () => {
  return (
    <div>
      <CheckoutPage/>
     
      
    </div>
  )
}

export default Checkout
