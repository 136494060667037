import React, { useEffect, useState } from 'react';
import Mapp from './Mapp';
import "./sdpMap.scss";

const SdpMap = ({ shelfData }) => {
  const britannicaLink = 'https://www.britannica.com/place/London';
  const { SelectedState } = shelfData;
  const selectedState = SelectedState;

  const addressMatch = selectedState?.match(/^(.*?)\"Latitude/);
  const address = addressMatch ? addressMatch[1].trim() : null;

  // Extracting latitude and longitude
  const latitudeMatch = selectedState?.match(/Latitude : ([-+]?\d*\.\d+)/);
  const longitudeMatch = selectedState?.match(/Longitude : ([-+]?\d*\.\d+)/);

  // Check if matches were found
  const latitude = latitudeMatch ? parseFloat(latitudeMatch[1]) : null;
  const longitude = longitudeMatch ? parseFloat(longitudeMatch[1]) : null;

  // State to hold location details
  const [locationDetails, setLocationDetails] = useState({
    address: '',
    latitude: null,
    longitude:null,
  });

  // Update locationDetails on mount
  useEffect(() => {
	// Check if both latitude and longitude are not null
	if (latitude !== null && longitude !== null) {
	  setLocationDetails({
		address,
		latitude,
		longitude,
	  });
	}
  }, [address, latitude, longitude]);

  //  console.log("at sdpmap ", locationDetails);

  return (
    
      <div className="sdpmapwhereYoullDisplayParent">
        <div className="sdpmapwhereYoullDisplay1">Where you’ll display</div>
        <div className="sdpmapdivider1" />
        <div className="sdpmapmapIcon">
          <Mapp locationDetails={locationDetails} />
        </div>
        <div className="sdpmaplondon">London </div>
        <div className="sdpmapveryDynamicAnd1" >
        Central London is the heart of the UK capital, pulsating with historical
         significance and modern charm. Home to iconic landmarks like Buckingham Palace,
          the area encapsulates the British monarchy's grandeur. Markets such as Borough Market,
           with roots dating back to the 12th century, showcase a vibrant food scene, offering 
           artisanal delights. Covent Garden, once a fruit and vegetable market, now dazzles with 
           boutique shops and street performances. The historic Tower of London stands as a symbol 
           of medieval power. Central London's seamless blend of history, markets, and contemporary 
           allure makes it an unparalleled destination for locals and tourists alike.
  </div>
        <div className="sdpmaplinkCell">
       
        <a href={britannicaLink} target="_blank" rel="noopener noreferrer">
          <div className="sdpmaplink">Show More</div>
        </a>
      </div>
        
      </div>
   
  );
};

export default SdpMap;
