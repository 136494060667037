import React from 'react'
import "./Help.css"
import { Link } from 'react-router-dom';

const Help = () => {
  return (
            <section className="help-container">
                  <div className="help-title">
                        How we help 
                        <span className="help-title-span">brands </span>
                        ?
                  </div>

                  <div className="help-content">
                  Did you know that on average,<span className='underline_issue'>D2C brands face 35% unsold inventory and a 25% increase in ad spending each year?</span> streamline and boost these sales by securing a shelf with ThirdRetail.
                  </div>
                  
                  <div className="help-synergy-btn btn btn-dark">
                        <Link className="btn-dark synergy-btn" to="Synergy">Get started</Link>
                        <img className="help-icon-arrow" alt="arrow icon to redirect to synergy platform" src="arrow.svg" />
                  </div>
                  
            </section>
      );
}
 
export default Help ;
