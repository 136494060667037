import React, { useState } from 'react'
import  "./myAccordino.scss"
const MyAccordino = ({question ,  answer}) => {
    const [show , setShow] = useState(false) ;
  return (
    <div className="questions-faq-custom">
        <div className="mainone">
           
            <div className='questionfaq'>{question}</div>
            <div className='plus' onClick={()=>setShow(!show)}>{show ? "—": "╋"}</div>
        </div>
     
   { show && <div className='answers '>{answer}</div> }   
    </div>
  )
}

export default MyAccordino
