import React, { useState  , useEffect} from 'react';
import { Link } from 'react-router-dom';
import Synergydatatile1 from './Synergydatatile1';
import { useNavigate } from 'react-router-dom';
import Synergydatatile2 from './Synergydatatile2';
import Synergydatatile5 from './Synergydatatile5';
import Synergydatatile4 from './Synergydatatile4';
import Synergydatatile3 from './Synergydatatile3';
import "./synergy1.scss"
import axios from 'axios';

const YourComponent = () => {
const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const [currentInfo, setCurrentInfo] = useState(1);
const navigate = useNavigate();
const [selectedOptions, setSelectedOptions] = useState([]);
const [selectedProduct, setSelectedProduct] = useState([]);
const [isLoading, setIsLoading] = useState(false);
  const handleSelectedOptionsChange = (options) => {
  setSelectedOptions(options);
  };
  const handleSelectedProductChange = (product) => {
  setSelectedProduct(product);
  };
  const handlePrev = () => {
  setCurrentInfo((prevInfo) => (prevInfo > 1 ? prevInfo - 1 : 6));
  };
  const handleNext = async () => {
  if (currentInfo === 5) {
  try {
  setIsLoading(true);
  await new Promise((resolve) => setTimeout(resolve, 500));
              const response = await axios.post(graphqlEndpoint, {
              query: `
              query{
                getSynergyMarketList(averageOrderValue : "",
                                    location : "",
                                    customerDemographics :[""],
                                    prefferedProduct : [""]){
                                    storeId,
                                    StoreName,
                                    AverageOrderValue,
                                    shelfDetails{
                                    shelfName,
                                    createdDate
                  }
                }
              }
              `,
              });
      const responseData = response.data.data;
      setIsLoading(false);
      navigate('/Browseshops', { state: { responseData } });
      } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      }
      } else {
      setCurrentInfo((prevInfo) => (prevInfo < 6 ? prevInfo + 1 : 1));
      }
      };




  const infoPages = [
  <Synergydatatile1  onSelectedOptionsChange={handleSelectedOptionsChange} />,
  <Synergydatatile2 selectedOptions={selectedOptions} selectedProduct={selectedProduct} onSelectedProductChange={handleSelectedProductChange} />,
  <Synergydatatile3/>,
  <Synergydatatile4/>,
  <Synergydatatile5/>,
  'Information for Page 6',
   ];

   const isPrevDisabled = currentInfo === 1;
   const isNextDisabled = currentInfo === 6;
  //  /<img src ="synergylogo.svg" alt ="" />
return (
 <div className='controlsynery'>
            

                <div className="controlstatementsynergy">      
                <div>{infoPages[currentInfo - 1]}</div>
               
               

            <div className="synergybutton">
                <div className='synergyprevbutton'>
                <button
                  id='synergybuttonid'
                onClick={handlePrev}
                disabled={isPrevDisabled}
                style={{ color: isPrevDisabled ? 'gray' : 'black' }}>
                Previous
                </button> </div>
                <div className='synergyprevbutton'>
                <button
                   id='synergybuttonid'
                onClick={handleNext}
                disabled={isNextDisabled}
                style={{ color: isNextDisabled ? 'gray' : 'black' }} >
                 Next
                 </button>
                 </div>

                 </div>
        </div>

 </div>


 );};

 export default YourComponent;
