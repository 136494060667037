import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ecomm_dash.scss';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const columns = [
  { id: 'date', text: 'Date' },
  { id: 'shopname', text: 'Shopname' },
  { id: 'location', text: 'Location' },
  { id: 'total_rent', text: 'Total Rent' },
  { id: 'status', text: 'Status' },
  { id: 'action', text: 'Action' }
];

const Table = ({ columns, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [selectedRow, setSelectedRow] = useState(null); // To store details of selected row
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  // Calculate the data to be displayed on the current page
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  // Calculate total pages
  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Handle page change
  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle click on View icon to open modal
  const handleViewDetails = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  // Handle click on Close button to close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.id}>{col.text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td
                  key={col.id}
                  className={
                    col.id === 'status'
                      ? row[col.id] === 'Confirmed'
                        ? 'status-confirmed'
                        : row[col.id] === 'Pending'
                        ? 'status-pending'
                        : 'status-cancelled'
                      : ''
                  }
                >
                  {col.id === 'action' ? (
                    <div className='icon_to_view_cancel'>
                      <img className="cancel_button" src="./cancel_button.svg" alt="cancel" style={{ width: '30px', height: '30px', cursor: 'pointer' }} />
                      <img
                        src="/view.svg"
                        alt="View"
                        style={{ width: '30px', height: '30px', cursor: 'pointer' }}
                        onClick={() => handleViewDetails(row)}
                      />
                    </div>
                  ) : (
                    row[col.id]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columns.length} className="pagination-cell">
              <div className="pagination-container">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    shape="rounded"
                  />
                </Stack>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      {isModalOpen && (
        <div>
          <div className="backdrop"></div>
          <div className="modal">
            <div className="modal-content">
              <div className="modal-header">
                <h2>View Details</h2>
                <span className="modal-close" onClick={handleCloseModal}>
                  <img className="cross_close" src="/cross_close.svg" alt="Close" />
                </span>
              </div>

              <div className="container_details">
              
              <img className="shelf_view" src="/shelf_view.svg" alt="Shelf View" />
                <div className="detail_desc">
                  <div className="booking_id">Booking ID: {selectedRow?.date}</div>
                  <div className="shop_name">{selectedRow?.shopname}</div>
                  <div className="shop_address">{selectedRow?.location}</div>
                  <div className="rack_details">Rack #1 </div>
                  <div className="date_no_of_shelf">
                    {selectedRow?.date} • 1 Shelves booked
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Awaiting_confirmation = () => {
  const [upcomingBookings, setUpcomingBookings] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const curr_user_id = sessionStorage.getItem('ecomUser_id');
        if (!curr_user_id) {
          setLoading(false);
          return;
        }
  console.log("id_upcoming", curr_user_id) ;
        const response = await axios.post(
          process.env.REACT_APP_GRAPHQL_ENDPOINT,
          {
            query: `
              query {
                getEcommDashboardData(ecommUserId: ${curr_user_id} , status: "AWAITING CONFIRMATION") {
                  ecomShelfBookedDates {
                    shelfDetail {
                      shelfName
                      shelfRent
                    }
                    bookingStartDate
                    bookingEndDate
                    status
                    bookingId
                  }
                  registerStore {
                    StoreName
                    storeId
                    SelectedState
                    stroreImageUrl
                  }
                }
              }
            `
          }
        );

        setUpcomingBookings(response.data.data.getEcommDashboardData);
        setLoading(false);
        console.log("awaiting 1", upcomingBookings)
      } catch (error) {
        console.error('Error fetching upcoming bookings:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [loading]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!upcomingBookings) {
    return <p>No upcoming bookings available.</p>;
  }
 
  const cleanLocation = (location) => {
    if (!location) return ''; // Return empty string if location is undefined or null
    // Remove "Latitude : <value> Longitude : <value>" and trim spaces
    let cleanedLocation = location.replace(/Latitude : [\d.-]+ Longitude : [\d.-]+,?/, '').trim();
    // Remove surrounding quotes if present
    cleanedLocation = cleanedLocation.replace(/^"|"$/g, '');
    return cleanedLocation;
  };

  const Data = upcomingBookings.ecomShelfBookedDates.map((item, index) => ({
    date: `${item.bookingStartDate} - ${item.bookingEndDate}`,
    store_img : upcomingBookings.registerStore[index]?.stroreImageUrl,
     shopname: upcomingBookings.registerStore[index]?.StoreName,
    location: cleanLocation(upcomingBookings.registerStore[index]?.SelectedState),
    total_rent: `$${item.shelfDetail?.shelfRent}/day`,
    status: item.status,
    action: 'ON' // Placeholder action
  }));

 const store_img =  upcomingBookings.ecomShelfBookedDates.map((item , index) =>({
  shelf_img : upcomingBookings.registerStore[index]?.stroreImageUrl,
 }))


  return (
    <div>
      <Table columns={columns} data={Data} img =  {store_img}/>
    </div>
  );
};

export default Awaiting_confirmation ;

