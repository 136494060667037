import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Synergydatatile1 = ({ onSelectedOptionsChange }) => {
  const options = ['FMCG', 'Fashion & Accessories', 'Health & beauty' , 'Electronics', 'DIY & Hardware', 'Furniture & Interiors', 'Gardening'];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const images = ['/fmcg-icon.svg', '/fashion-icon.svg', '/health_beauty.svg' ,'/electronics-icon.svg',  '/diy-icon.svg', '/furniture-icon.svg', '/gardening-icon.svg'];
  useEffect(() => {
    sessionStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
  }, [selectedOptions]);

  
      // Pass the selected options to the parent component
      onSelectedOptionsChange(selectedOptions);
      
    //  console.log(sessionStorage.getItem('selectedOptions'))
    // Log selected options dynamically
   //  console.log('Selected Options:', selectedOptions);
    const handleButtonClick = (option) => {
      setSelectedOptions((prevOptions) => {
        if (prevOptions.includes(option)) {
          return prevOptions.filter((selected) => selected !== option);
        } else {
          return [...prevOptions, option];
        }
      });
    };
  


  return (
    <div className='synergydatatile1'>

       <div className="synergydataleft">

        
          <div className="logo_summary">
       <div className="synergy_icon_left_top">
       <Link to="/">
      <img src="/synergy_logo.svg" alt="Synergy Logo" />
    </Link>
         </div>
         <div className="synergy_text">Create the most affordable omnichannel experience for your product.</div>
         </div>



         <div className="synergy_insight_section">
        
         <div className="synergy_insight_data">
     
       


          
            <div className="side_img_synergy_insight">
            <div className="synergy_insight_percentage">64%</div>
            <div className="img_animation_section">
         <img className="animated-image_insight top-right"  src="/icon_insight_1.svg" alt=""  />
         <img src="/icon_insight_2" alt="" className="animated-image_insight top-right" />
     
         </div>
        </div> 

        
            <div className="synergy_insight_data_text">of global retail businesses are still recovering from the negative impact of the pandemic. (Credits : Shopify)</div>


            <div className="synergy_insight_controller">
              <div className="synergy_insight_controller_left"></div>
              <div className="synergy_insight_progressbar"><img src ="/progessbar_1.svg" alt ="" /></div>
              <div className="synergy_insight_controller_right"></div>
              </div>


            </div>
          
         </div>
       
     
       
       </div>
    <div className="synergyrightside">
          <div className="synergydataright">
          <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>
          </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">Tell us what kind of products do you want to shelf out?</div>
      
        <div className="optionsynergytile1">
       
          {options.map((option, index) => (
            <div
              key={index}
              id = "synergyicontile"
              className={`synergyicontile ${selectedOptions.includes(option) ? 'selected' : ''}`}
              onClick={() => handleButtonClick(option)}
            >
             
              <img src={images[index]} alt={`${option} Icon`} style ={{marginRight : "10px "}} />
              <span>{option}</span>
            </div>
          ))}
        </div>  
        </div>
      </div>
    </div>
  );
};

export default Synergydatatile1;