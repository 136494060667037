import React, { useEffect, useState, useCallback } from 'react';
import "./Form1.scss";
import { Link } from "react-router-dom"
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import AWS from 'aws-sdk';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form14 = (props) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const [uploadStatus, setUploadStatus] = useState('');
  const [newImageUrls, setNewImageUrls] = useState([]);
  const [progress, setProgress] = useState(0); // Added state to track progress

  const onDrop = useCallback(async (acceptedFiles) => {
    const s3 = new AWS.S3({
      region: 'us-east-1',
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
    });

    try {
      const uploadPromises = acceptedFiles.map(async (file) => {
        const params = {
          Bucket: 'imageofstore',
          Key: file.name,
          Body: file,
          ACL: 'public-read', // Adjust the ACL based on your requirements
        };

        const upload = s3.upload(params);

        // Tracking the progress of the upload
        upload.on('httpUploadProgress', (event) => {
          const percentCompleted = Math.round((event.loaded / event.total) * 100);
          setProgress(percentCompleted); // Update progress bar as the upload progresses
        });

        const response = await upload.promise();

        const imageUrl = response.Location;
        return imageUrl;
      });

      const newImageUrls = await Promise.all(uploadPromises);

      setNewImageUrls(newImageUrls);
      setUploadStatus('Upload successful!');
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus('Upload failed.');
    }
  }, [accessKeyId, secretAccessKey]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

  const [formData, setFormData] = useState({ storeId: localStorage.getItem('register_store') || '', listOfUrl: newImageUrls });

  useEffect(() => {
    const handleStorageChange = () => {
      const storeId = localStorage.getItem('register_store');
      setFormData((prevFormData) => ({ ...prevFormData, storeId: storeId || '' }));
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [newImageUrls]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storeIdAsLong = parseInt(formData.storeId, 10);
      const response = await axios.post(graphqlEndpoint, {
        query: `
          mutation SetStoreImage($listOfUrl: [String!], $storeId: Long!) {
            setStoreImage(storeId: $storeId, listOfUrl: $listOfUrl) {
              StoreName
            }
          }`,
        variables: {
          listOfUrl: newImageUrls,
          storeId: storeIdAsLong,
        },
      });

      setSaveClicked(true);
      toast.success('Images saved successfully!');
      setFormData({ storeId: props.register_store || "", listOfUrl: [] });
    } catch (error) {
      console.log(error);
    }
  };

  const [saveClicked, setSaveClicked] = useState(false);
  const handleNextClick = () => {
    if (!saveClicked) {
      toast.error('Please click "Save Images" first.');
    } else {
      window.location.href = '/Listyourshelf';
    }
  };

  return (
    <div className="form1fourthblock">
      <div className="fourthblockimageSection1">
        <div className="fourthblockstore">
          <div className="fourthblock_headinetext">
            <div className="fourthblockstoreImages">Store Images</div>
            <div className="fourthblocktellUsAbout1">
              Tell us about your Store. These specifics will help us figure out the best clients for you
            </div>
          </div>
        </div>

        <div className="drop_zone_and_submision">
          <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            <div className="fourthblockuploadfiles">
              <img className="fourthblockocticonupload161" alt="" src="https://i.postimg.cc/BQ7jZ1hX/Frame-3508.png" />
              <div className="fourthblocktextcontent">
                <div className="fourthblockclickHereToContainer">
                  <span className="fourthblocktoUploadOr">Drop media here</span>
                  <div className="fourthblocktoUploadOr"> Please upload at least 2 images</div>
                </div>
              </div>
            </div>
                  {/* Progress Bar */}
{progress > 0 && (
  <div className='progress_bar_container' >

 <div className='progress_percentage'>{progress}%</div> 



  <div className="progress-bar">
    <div 
      className="progress" 
      style={{ width: `${progress}%`, backgroundColor: '#09a7b3', height: '10px' }} // Multiply width to expand from center
    />
 
  </div>
  </div>
   
)}

          </div>
          
         
          <div className='loading_status'>{uploadStatus}</div>


          <div className="imgurlbutton">
            <button type="submit" id="buttonk" onClick={handleSubmit}>
              Save Images
            </button>
            <Link to="#" id="buttonimg" onClick={handleNextClick}>
              Next Page
            </Link>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form14;
