import React, { useEffect, useState, useRef } from 'react';
import CountUp from 'react-countup';
import './aboutstatistics.scss';

const Aboutstatistics = () => {
   const [startAnimation, setStartAnimation] = useState(false);
   const statisticsRef = useRef(null);

   // Use IntersectionObserver to detect when the component is in the viewport
   useEffect(() => {
      const observer = new IntersectionObserver(
         (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
               setStartAnimation(true);
            }
         },
         {
            threshold: 0.5, // Trigger animation when 50% of the component is visible
         }
      );

      if (statisticsRef.current) {
         observer.observe(statisticsRef.current);
      }

      return () => {
         if (statisticsRef.current) {
            observer.unobserve(statisticsRef.current);
         }
      };
   }, []);

   return (
      <div className="statisticsstatsParent" ref={statisticsRef}>
         <div className="statistics_headlines">
            <div className="statistics_main">Our waitlist</div>
            <div className="statistics_brand">
               <div className="stats_data" >
                  {startAnimation ? <CountUp end={10} duration={2} /> : '0'}+
               </div>
               <div className="stats_desc">Brands trust us</div>
            </div>
            <div className="statistics_register">
               <div className="stats_data">
                  {startAnimation ? <CountUp end={40} duration={2} /> : '0'}+
               </div>
               <div className="stats_desc">Stores registered with us</div>
            </div>
            <div className="statistics_customer">
               <div className="stats_data">
                  {startAnimation ? <CountUp end={100} duration={2} /> : '0'}%
               </div>
               <div className="stats_desc">Happy customers</div>
            </div>
         </div>
      </div>
   );
};

export default Aboutstatistics;
