
export const data = [
    {
      id: 1,
      image: "HandCoins.svg",
      title: "Save on ads spend",
      description: "Cut down on advertising costs while achieving effective customer acquisition through our budget-friendly offline shelf network",
    },
    {
      id: 2,
      image: "ChartPieSlice.svg",
      title: "Expand smoothly, sell more",
      description: "Fuel your product launch or expansion with a hassle-free new sales channel – access our recommended shelves for tailored growth.",
    },
    {
      id: 3,
      image: "UsersFour.svg",
      title: "Bring your product closer",
      description: "Put your product where it matters – local shelves. Bridge the gap between online presence for a richer customer experience.",
    },
    {
      id: 4,
      image: "ChartLine.svg",
      title: "Impactful operations",
      description: "Remove your cross-channel blind spot, while we predict, generate AI-driven insights, and reduce wastage for you .",
    },
  ]