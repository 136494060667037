import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';


  const ShelfComponent = ({ rackNumber, shelfName, shelfImage, shelfDescription, shelf_sort_subheadline,shelfRent, onSelect  ,shelfArea}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isOverlayVisible, setOverlayVisible] = useState(false);

  const handleHover = () => {
    setOverlayVisible(true);
  };

  const handleMouseLeave = () => {
    handleClose(); // Close the overlay when the mouse leaves the overlay
  };

  const handleClose = () => {
    setOverlayVisible(false);
  };
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
      onSelect({ rackNumber, shelfName, shelfRent, isChecked: !isChecked });
    };
  // console.log(shelfImage)
  
  // mobile or not 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div className="sdpselfrack">
    
                          <div className="shelf_details_sdp"   >

                               <img className="shelf_image_sdp" alt="" src={shelfImage} />
                                  
                            
                                <div className="shelf_desc_sdp">
                    
                                {isMobile && (
                                 <div className="image-container" >
         <img className="icon_insight_sdp_mobile" onMouseEnter={handleHover}src="/icon_insight_1.svg" alt="" />
      {isOverlayVisible && (
        <div className="overlay">
          <div className="storeDescription">
          
             <div className='shelf_description_per_shelf'  onMouseLeave={handleMouseLeave}>
             {shelfDescription} 
               </div>
               <button className="close-btn" onClick={handleClose}>Close</button>
            </div>
        </div>
      )}
                                </div> )}
                                <div className="shelf_no_desc">
                                  <div className="sdp_shelf_no" style={{ fontWeight: "600" }}>{shelfName}</div>
                                  <div className="sdp_self_desc" style={{ color: "#001325a3" }}>{shelf_sort_subheadline}</div>
                                </div>


                                  <div className="shelf_rent_sdp_area">
                              
                                  <div className="shelf_desc_rent_sdp">
                                            <div className="shelf_rent_sdp" >{`£${shelfRent}`}</div>
                                          
                                               <div className="" style={{fontSize : "1rem"}}>/shelf</div>
                                     </div>

                                       <div className='shelf_area'>•{shelfArea} cm</div>
                                                    </div>  

                                   </div>



                           
                              <div className="sdp_check_box" >
                                 <input type="checkbox" checked={isChecked}  onChange={handleCheckboxChange}  style={{ width: '20px', height: '20px', borderRadius: '5px' }}/>

                                 <div className='sdp_shelf_area' > Select Shelf</div>
                                 {!isMobile && (
                                 <div className="image-container" >
         <img className="icon_insight_sdp" onMouseEnter={handleHover}src="/icon_insight_1.svg" alt="" />
      {isOverlayVisible && (
        <div className="overlay">
          <div className="storeDescription">
          
             <div className='shelf_description_per_shelf'  onMouseLeave={handleMouseLeave}>
             {shelfDescription} 
               </div>
               <button className="close-btn" onClick={handleClose}>Close</button>

      
           
            </div>
        </div>
      )}
                                </div> )}
    
                                   </div>
                             
                    
                            
       
      </div>


    
       


       
     
      
    </div>
  );
};

ShelfComponent.propTypes = {
  rackNumber: PropTypes.number.isRequired,
  shelfImage: PropTypes.string.isRequired,
  shelfDescription: PropTypes.string.isRequired,
  shelfRent: PropTypes.number.isRequired,
 isSelected: PropTypes.bool,
};

export default ShelfComponent;


