import React from 'react'
import "./Brand.css"


import brand_1 from './brand/brand_1.png' ;
import brand_2 from './brand/brand_2.png';
import brand_3 from './brand/brand_3.png';
import brand_4 from './brand/brand_4.png';
import brand_5 from './brand/brand_5.png';
import brand_6 from './brand/brand_6.png';


const Brand = () => {

    const gaps = [
        {
            id: 1,
          icon : brand_1 ,
            text: "Find your synergy",
            desc : "Search for perfect shelves as per our synergy flow."
        },
        {
            id: 2,
            icon : brand_2 ,
            text: "Book a shelf",
            desc : "Choose suitable dates as and make booking as per you."
        },
        {
            id: 3,
            icon : brand_3 ,
            text: "Ship your product" ,
            desc : "Deliver your products to the store before the start date."
        },
        {
            id: 4,
            icon : brand_4 ,
            text: "Get discovered",
            desc : "Feel free to send marketing materials for instore promotions."
        },
        {
            id: 5,
            icon : brand_5 ,
            text: "Grow your business",
            desc : "Get on a shelf in your target customer's nearby stores and start selling"
        },
        {
            id: 6,
            icon : brand_6 ,
            text: "AI-led insights",
            desc : "In Pipeline"
        },
    ];
  return (
   
            <section className="brand-container">
             

                    <div className="brand-gap-centre">
                        <div className="brand-gap-highlight">
                                Bridging the gap between Online & Offline Retail
                        </div>
                        <div className="brand-gap-title">
                            <div className="brands">How it</div>
                            <span className="brand_works_color">works ?</span>
                        </div>

                        <div className="brand-gap-subtitle">
                            {
                                gaps.map((gap) => (
                                    <div className="borderof_brand">
                                    <div className="brand-gap" key={gap.id}>
                                        <div className="brand_Data_all">
                                            <div className="brand_headandicon">
                                            <div className="gap-icon"> <img className="brand_img_section" src={gap.icon} alt="" /></div>
                                        <div className="gap-text">{gap.text}</div>
                                        </div>
                                        <div className="brand_desc">
                                        <div className="gap-desc">{gap.desc}</div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
             
            </section>
        );
  };
    
   

export default Brand
