import React from 'react'
import Confirmationpage from '../../components/confirmation/Confirmationpage'
const Confirmation = () => {
  return (
    <div>
      <Confirmationpage/>
    </div>
  )
}

export default Confirmation
