import React, { useEffect , useState } from 'react';
import "./Form1.scss" ;
import axios from 'axios';
import "./Form1.scss"
import Select from 'react-select';
import {Link}  from "react-router-dom" 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Multiselect from 'multiselect-react-dropdown';


const Form12 = (props) => {
                   const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
                   const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
	                 const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
                   const handleSaveClick = async () => {
		               try {
		               setIsSaveButtonClicked(true);
		               toast.success('Data saved successfully!');
		               setIsNextButtonDisabled(false); 
		               } catch (error) {
		               console.error('Save error:', error);   } };
                   const handleNextClick = () => {
                   if (!isSaveButtonClicked) {
                   toast.error('Please click "Save" before proceeding to "Next".');
                   } else {
                   toast.info('Proceeding to the next step.');
                   } };





        const [formData7, setFormData7] = useState({
        id: props.storeId  || "",
        storeType: [],
        averageOrderValue: '', // Assuming averageOrderValue is a Long on the backend
        customerDemographics: [], // Assuming customerDemographics is a List<String> on the backend
        paymentReconciliation: '',
        prefferedProduct: [], // Assuming prefferedProduct is a List<String> on the backend
        shopCategory: [], // Assuming shopCategory is a List<String> on the backend
          });

       useEffect(() => {
        setFormData7((prevFormData7) => ({ ...prevFormData7, id: props.storeId || '' }));
      }, [props.storeId]);
     

       // 1--cutomer demographicss 2-- averageOrderValue 3-- payemet-recoincailation  4-- prefereeed product 


       const [selectedType, setSelectedType] = useState(''); 
       const [selectedAverageOrderValue, setSelectedValue] = useState('');
       const [selectedDemographics, setSelectedDemographics] = useState('');
       const [selectedReconciliation, setSelectedReconciliation] = useState('');
       const [selectedCategory, setSelectedCategory] = useState('');
       const [selectedCategories, setSelectedCategories] = useState([]); 
       const [preferredProducts, setPreferredProducts] = useState([]);
       const [selectedProducts, setSelectedProducts] = useState([]);
     
     
     
       // List of states and cities (replace with your actual data)
       const Types = ['Chain Store', 'Small Store', 'Brand Store' , 'Supermarket','Multi Brand','Boutique Store','Others'];
       const Values = ['under £50', '£50 -£100', '£100-£200','£200-£500','over £500',];
       const Demographicss = ['Baby products', 'Minor', '18-24' , '25-35','45-65','65+'];
       const Reconciliations = ['30 days', '60 days ', '90+ days'];
     //  const Products = ['Fresh Products', 'Packaged goods', 'Alcohol', "Men's clothing" , "Women's clothing" ,"Televisons",'Computers'] ;
       const Categorys = ['FMCG', 'Fashion & Accessories','Kids','Furniture & Interiors' , 'Health & beauty' , 'Sports' , 'Office supplies' , 'Pet supplies','Electronics','Books and music','DIY & Hardware','Gardening'];
     
       useEffect(() => {
        // Fetch preferred products when the component mounts or when the selected category changes
        if (selectedCategory) {
          fetchPreferredProducts(selectedCategory);
        }
      }, [selectedCategory]);
      useEffect(() => {
        if (selectedCategories.length > 0) {
          // Fetch preferred products for the last selected category
          const lastCategory = selectedCategories[selectedCategories.length - 1];
          fetchPreferredProducts(lastCategory);
        }
      }, [selectedCategories]);

       const handlestoreTypeChange = (event) => {
        setSelectedType(event.target.value);      
      };
      const handleCustomerDemographicsChange = (selectedOptions) => {
        setSelectedDemographics(selectedOptions);
        setFormData7({ ...formData7, customerDemographics: selectedOptions.map(option => option.value) });
      };

          const handleaverageOrderValueChange = (event) => {
            setSelectedValue(event.target.value); 
          };

       
          const handlepaymentReconciliationChange = (event) => {
            setSelectedReconciliation(event.target.value); 
          };
          // Function to handle city selection
          const handleSubmit = async (e) => {
            e.preventDefault();
            
            // Include the selected products in your mutation or API call
       //     console.log('Selected Products:', selectedProducts);
          };
          const handleCategoryChange = (selectedOption) => {
            setSelectedCategory(selectedOption.value);
            const newCategory = selectedOption.value;
            setSelectedCategories((prevCategories) => [...prevCategories, newCategory]);
          };
        
          const handleProductChange = (selectedOptions) => {
            setSelectedProducts(selectedOptions);
          };
       
          const fetchPreferredProducts = async (category) => {
            try {
              const response = await axios.post(graphqlEndpoint, {
                query: `
                  query {
                    getStoreByRetailCategory(retailCategory: "${category}") {
                      preferredProduct
                    }
                  }
                `,
              });
        
              // Update the preferredProducts state with the fetched data
            // Fetch the list of preferred products from the response
            let preferredProducts = response.data.data.getStoreByRetailCategory.preferredProduct;

            // Append additional options based on the category
          
              preferredProducts = [...preferredProducts, 'Others'];
      
            // Update the preferredProducts state with the updated list
            setPreferredProducts(preferredProducts);


  // Update the preferredProducts state with the updated list
  setPreferredProducts(preferredProducts);
        
         //     console.log(response.data.data.getStoreByRetailCategory.preferredProduct);
            } catch (error) {
              console.error('Error fetching preferred products:', error);
            }
          };
     
        
   
     
       const handleSubmit7 = async (e) => {
         e.preventDefault();
     
         try {
           // Make a POST request to the GraphQL endpoint
           const response = await axios.post(graphqlEndpoint, {
             query: `
               mutation storeBusinessInformation(
                 $id: Long!,
                 $storeType: [String]!,
                 $averageOrderValue:  String!,
                 $customerDemographics: [String]!,
                 $paymentReconciliation: String!,
                 $prefferedProduct: [String]!,
                 $shopCategory: [String]!
               ) {
                 storeBusinessInformation(
                   id: $id,
                   storeType: $storeType,
                   averageOrderValue: $averageOrderValue,
                   customerDemographics: $customerDemographics,
                   paymentReconciliation: $paymentReconciliation,
                   prefferedProduct: $prefferedProduct,
                   shopCategory: $shopCategory
                 )
               }
             `,
             variables: {
               id: parseInt(formData7.id), // Assuming id is a Long on the backend
               storeType: formData7.storeType,
               averageOrderValue: formData7.averageOrderValue, // Convert to Long
               customerDemographics: formData7.customerDemographics,
               paymentReconciliation: formData7.paymentReconciliation,
               prefferedProduct: formData7.prefferedProduct,
               shopCategory: formData7.shopCategory,
             },
           });
     
           // Handle the response data
     //      console.log(response.data);
           const storeBusiness = response.data.data.storeBusinessInformation;
           setFormData7({
            id: props.storeId  || "",
             storeType: [],
             averageOrderValue: '',
             customerDemographics: [],
             paymentReconciliation: '',
             prefferedProduct: [],
             shopCategory: [],
           });
         } catch (error) {
           // Handle errors
           console.error(error);
         }
       };
     
      
 
       return (
        
  
<div className="form1secondblock">
  
    		<div className="businessInformation">


        <div className="thirddata">
        <div className="fourthdatabox">
        				<div className="storetitle">Business Information</div>
        				<div className="storetitledata">Tell us about your Store. These specifics will help us figure out the best clients for you.</div>
      			</div>
         </div>


				  <form onSubmit={handleSubmit7}>
      			<div className="businessframeParent">


        				     <div className="businessselectFieldParent">


          		     	<div className="businessselectField">
                        <div className="businesslabel" style={{color:"#728197"}}>Customer demography</div> 
              <div className="business_input">
<Select
  isMulti
  options={Demographicss.map(type => ({ value: type, label: type }))}
  value={selectedDemographics}
  placeholder="Select customer demography"
  styles={{
 
      placeholder: (provided) => ({
        ...provided,
        color: '#9AA8BC',        // Customize placeholder color
        fontSize: '0.8rem',        // Customize placeholder font size
  
      }) ,
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: '2rem',    // Set max height for the container of selected values
      overflowY: 'auto',    // Enable scrolling for selected values when they overflow
      paddingRight: '10px', // Add some padding to avoid overlap with the dropdown indicator
    }),
  
  }}
  onChange={(selectedOptions) => handleCustomerDemographicsChange(selectedOptions)}
  
/>
</div>
            					
          					</div>
          				
                  	<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Payment reconciliation</div>
            						</div>
            						<div className="business_input">  
                                    <select
                                            className="customSelect_selected_property"
                    value={selectedReconciliation|| formData7.paymentReconciliation} // Use a default value if needed
					onChange={(e) => {
					  setSelectedReconciliation() ;
					  setFormData7({ ...formData7, paymentReconciliation: e.target.value });
					}} 
                >
                  <option value=""  >Select an option...</option>
                  {Reconciliations.map((Reconciliation, index) => (
                    <option key={index} value={Reconciliation}>
                      {Reconciliation}
                    </option>
                  ))}
                          </select>
            						</div>
          					</div>


        				    </div>

        				<div className="businessselectFieldParent">
          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Store type</div>
            						</div>
            						<div className="business_input" >
                                    <select
                                            className="customSelect_selected_property"
                   value={selectedType || formData7.storeType} // Use a default value if needed
					         onChange={(e) => {
					         setSelectedType() ;
					         setFormData7({ ...formData7, storeType: e.target.value }); 
				                        	}} 
                     >
                  <option value=""  >Select an option...</option>
                  {Types.map((Type, index) => (
                    <option key={index} value={Type}>
                      {Type}
                    </option>      
                    ))} 
                      </select>
            						</div>
          					</div>


                  

                    <div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Average order value</div>
            						</div>
            						<div className="business_input">
                        <select
                           id ="select_property"
                           className="customSelect_selected_property"
                            value={selectedAverageOrderValue || formData7.averageOrderValue} // Use a default value if needed
                          onChange={(e) => { 
                        setSelectedValue() ;
                        setFormData7({ ...formData7, averageOrderValue: e.target.value }); 
                      }}
                     
                            >
                              <option value=""  >Select an option...</option>
                              {Values.map((Value, index) => (
                                <option key={index} value={Value}>
                                  {Value}
                                </option>
                            ))}   
                              
            </select>
            						</div>
          					</div>

        				</div>
               
    



        				<div className="businessselectFieldParent">


          			


                    <div className="businessselectField">
            						<div className="businesslabelWrapper">
                        <div className="businesslabel" style={{color:"#728197"}}>Shop Category</div> 
            						</div>
            						<div className="business_input">
                        
                        <Select  
                        
                            value={selectedCategory || formData7.shopCategory}
                            options={Categorys.map(category => ({ value: category, label: category }))}
                            onChange={(selectedOption) => {
                            setSelectedCategory(selectedOption);
                            setFormData7({ ...formData7, shopCategory: selectedOption.value });
                            handleCategoryChange(selectedOption);
                                     }}
                                     placeholder="Select a category"
                                     styles={ {
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: '#9AA8BC',        // Customize placeholder color
                                        fontSize: '0.8rem',        // Customize placeholder font size
                                      }),
                                     }}
                          />
                        
            						   </div>
          					       </div>

          					<div className="businessselectField">
            						<div className="businesslabelWrapper">
              							<div className="businesslabel" style={{color:"#728197"}}>Preferred product </div>
            						</div>
            						<div className="business_input">
                        <Select
  isMulti
  value={selectedProducts}
  options={preferredProducts.map(product => ({ value: product, label: product }))}
  onChange={(selectedOptions) => {
    setSelectedProducts(selectedOptions);
    setFormData7({ ...formData7, prefferedProduct: selectedOptions.map(option => option.value) });
    handleProductChange(selectedOptions);
  }}
  placeholder="Select a product"
  styles={{
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: '2rem',       // Set max height for the container of selected values
      overflowY: 'auto',       // Enable scrolling for selected values when they overflow
      paddingRight: '10px',    // Add padding to avoid overlap with the dropdown indicator
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9AA8BC',        // Customize placeholder color
      fontSize: '0.8rem',        // Customize placeholder font size
    }),
  }}
/>

            </div>
                    </div>
        	     	</div>

                 <Select
          isMulti
          value={selectedCategories.map(category => ({ value: category, label: category }))}
          isDisabled // Disable it to prevent user interaction, it only shows selected categories
          placeholder="Selected categories..."
          styles={{
            placeholder: (provided) => ({
              ...provided,
              color: '#9AA8BC',        // Customize placeholder color
              fontSize: '0.8rem',        // Customize placeholder font size
            }),
          }}
        />

      			</div>
				
            <div className="linksummit">
        <button type="submit" id="button_form12" onClick={handleSaveClick}>
          Save
        </button>
        <Link className={`linkform ${isNextButtonDisabled ? 'disabled' : ''}`} to={isSaveButtonClicked ? "Form13" : ""} onClick={handleNextClick} style = {{textDecoration : "none"}}>
          Next
        </Link>
      </div>

            
      	


       
      </form>
  


    		</div>



</div>

      


  
  )
}

export default Form12 ;





















