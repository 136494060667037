import dayjs from "dayjs";
import React, { useState, useRef, useMemo, useEffect } from "react";
import dateRangeStyle from "./dateRangePicker.module.css";

const DateRangePicker = ({
  weekStartDay,
  weekSize,
  containerWidth,
  setStartDate,
  setEndDate,
  openState,
  parentRef,
  focusState,
  applyFunc,
  cancelFunc,
}) => {
  const [firstDate, setFirstDate] = useState(dayjs());
  const [secondDate, setSecondDate] = useState(dayjs().add(1, "month"));

  const [hoverDate, setHoverDate] = useState(null);
  const [selectedFirstDate, setSelectedFirstDate] = useState(null);
  const [selectedLastDate, setSelectedLastDate] = useState(null);

  const containerRef = useRef(null);

  const getDate = (first, second) => {
    const difference = first + 1 - (second + 1);
    if (difference === 0) {
      return 0;
    } else if (difference > 0) {
      return difference;
    }
    return difference + 7;
  };
  const calendarGridValue = (year, month) => {
    const weekDayStart =
      weekStartDay && !isNaN(parseInt(weekStartDay, 10))
        ? parseInt(weekStartDay, 10)
        : 0;
    const firstDayOfMonth = dayjs().year(year).month(month).startOf("month");
    const firstCalenderDay = firstDayOfMonth.subtract(
      getDate(firstDayOfMonth.day(), weekDayStart),
      "day"
    );

    const dayList = [];
    let loopDate = firstCalenderDay;
    for (let i = 0; i < 42; i++) {
      dayList.push(loopDate);
      loopDate = loopDate.add(1, "day");
    }
    return dayList;
  };

  const changePreviousYear = () => {
    setFirstDate((prev) => prev.subtract(1, "month"));
    setSecondDate((prev) => prev.subtract(1, "month"));
  };

  const changeNextYear = () => {
    setFirstDate((prev) => prev.add(1, "month"));
    setSecondDate((prev) => prev.add(1, "month"));
  };

  const calenderClick = (date, calenderSide) => {
    if (!date.isSame(firstDate, "Month") && calenderSide === "right") {
      return;
    }
    if (!date.isSame(secondDate, "Month") && calenderSide === "left") {
      return;
    }
    if (selectedLastDate !== null) {
      setSelectedLastDate(null);
      setSelectedFirstDate(date);
      return;
    }
    if (selectedFirstDate !== null) {
      if (selectedFirstDate.isSame(date, "day")) {
        return;
      }
      if (selectedFirstDate.isBefore(date)) {
        setSelectedLastDate(date);
      } else {
        setSelectedFirstDate(date);
      }
      return;
    }
    setSelectedFirstDate(date);
  };

  const getColor = (date) => {
    if (date.isSame(selectedFirstDate, "day")) {
      return dateRangeStyle.calenderCellStart;
    }
    if (date.isSame(selectedLastDate, "day")) {
      return dateRangeStyle.calenderCellEnd;
    }
    if (selectedFirstDate !== null && selectedLastDate !== null) {
      if (date.isAfter(selectedFirstDate) && date.isBefore(selectedLastDate)) {
        return dateRangeStyle.calenderCellInbetween;
      }
    }
    if (selectedFirstDate !== null && selectedLastDate === null) {
      if (date.isAfter(selectedFirstDate) && date.isBefore(hoverDate)) {
        return dateRangeStyle.calenderCellInbetween;
      }
    }
    return dateRangeStyle.calenderCellNormal;
  };

  const resetAll = () => {
    setHoverDate(null);
    setSelectedFirstDate(null);
    setSelectedLastDate(null);
    focusState(false);
    openState(false);
  };

  const cancelButton = () => {
    setStartDate(null);
    setEndDate(null);
    cancelFunc && cancelFunc();
    resetAll();
  };

  const applyButton = () => {
    setStartDate(selectedFirstDate);
    setEndDate(selectedLastDate);
    applyFunc && applyFunc();
    resetAll();
  };

  function handleClickOutside(event) {
    event.preventDefault();
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      !parentRef.current.contains(event.target)
    ) {
      focusState(false);
      openState(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getWeekDays = useMemo(() => {
    const startDayOfWeek =
      weekStartDay && !isNaN(parseInt(weekStartDay, 10))
        ? parseInt(weekStartDay, 10)
        : 0;
    const daysOfWeek = [];
    for (let i = startDayOfWeek; i < startDayOfWeek + 7; i++) {
      const dayName = dayjs()
        .day(i % 7)
        .format("dddd");
      daysOfWeek.push(dayName);
    }
    return daysOfWeek;
  }, [weekStartDay]);

  const getCalenderGridFirst = useMemo(() => {
    return calendarGridValue(firstDate.year(), firstDate.month());
  }, [weekStartDay, firstDate]);

  const getCalenderGridSecond = useMemo(() => {
    return calendarGridValue(secondDate.year(), secondDate.month());
  }, [weekStartDay, secondDate]);

  const monthName = useMemo(() => {
    const monthList = [];
    for (let month = 0; month < 12; month++) {
      monthList.push(dayjs().month(month).format("MMMM"));
    }
    return monthList;
  }, []);
  const handleClearDates = () => {
    sessionStorage.removeItem("selectedStartDate");
    sessionStorage.removeItem("selectedEndDate");
   
    window.location.reload();
  };
  return (


    
    <div
      style={{ width: containerWidth }}
      ref={containerRef}
      className={dateRangeStyle.dateRangeContainer}
    >
    <div className={dateRangeStyle.topcalender}>
        <div className={dateRangeStyle.topcalenderheadline}>Available Dates</div>
        <div className={dateRangeStyle.topcalenderclear} onClick={handleClearDates}>
          Clear Dates
        </div>
      </div>

      <div className={dateRangeStyle.calenderContainer}>
        <div className={dateRangeStyle.rightCalender}>
          <div className={dateRangeStyle.headerCalender}>
            <div
              className={dateRangeStyle.nextButton}
              onClick={changePreviousYear}
            >
              {"<"}
            </div>
            <div className={dateRangeStyle.yearMonthTitle}>
              {monthName[firstDate.month()]} {firstDate.year()}
            </div>
          </div>
        
          <div className={dateRangeStyle.weekContainer}>
            {getWeekDays.map((weekDay) => (
              <div className={dateRangeStyle.weekCells}>
                {weekDay.slice(0, 3)}
              </div>
            ))}
          </div>
          <div className={dateRangeStyle.dateContainer}>
            {getCalenderGridFirst.map((dayValue) => (
              <div
                className={`${dateRangeStyle.dayCells} ${getColor(dayValue)} ${
                  !dayValue.isSame(firstDate, "Month") &&
                  dateRangeStyle.calenderCellDisabled
                }`}
                onClick={() => calenderClick(dayValue, "right")}
                onMouseEnter={() => {
                  if (dayValue.isSame(firstDate, "Month")) {
                    setHoverDate(dayValue);
                  }
                }}
                onMouseLeave={() => {
                  if (dayValue.isSame(firstDate, "Month")) {
                    setHoverDate(null);
                  }
                }}
              >
                {dayValue.$D}
              </div>
            ))}
          </div>
        </div>
        <div className={dateRangeStyle.leftCalender}>
          <div className={dateRangeStyle.headerCalender}>
            <div className={dateRangeStyle.yearMonthTitle}>
              {monthName[secondDate.month()]} {secondDate.year()}
            </div>
            <div className={dateRangeStyle.nextButton} onClick={changeNextYear}>
              {">"}
            </div>
          </div>
          <div className={dateRangeStyle.weekContainer}>
            {getWeekDays.map((weekDay) => (
              <div className={dateRangeStyle.weekCells}>
                {weekDay.slice(0, 3)}
              </div>
            ))}
          </div>
          <div className={dateRangeStyle.dateContainer}>
            {getCalenderGridSecond.map((dayValue) => (
              <div
                className={`${dateRangeStyle.dayCells} ${getColor(dayValue)} ${
                  !dayValue.isSame(secondDate, "Month") &&
                  dateRangeStyle.calenderCellDisabled
                }`}
                onClick={() => calenderClick(dayValue, "left")}
                onMouseEnter={() => {
                  if (dayValue.isSame(secondDate, "Month")) {
                    setHoverDate(dayValue);
                  }
                }}
                onMouseLeave={() => {
                  if (dayValue.isSame(secondDate, "Month")) {
                    setHoverDate(null);
                  }
                }}
              >
                {dayValue.$D}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={dateRangeStyle.accessories}>
        <button
          onClick={applyButton}
          className={`${dateRangeStyle.buttonCommon} ${dateRangeStyle.applyButton}`}
        >
          Apply
        </button>
        <button
          onClick={cancelButton}
          className={`${dateRangeStyle.buttonCommon} ${dateRangeStyle.cancelButton}`}
        >
        Close
        </button>
      </div>
    </div>
  );
};

export default DateRangePicker;
