import React from 'react'
import "./Business.css"
import "./BusinessInfo.css"

import { Link } from 'react-router-dom';

const data = [
      {
            id: 1,
            title: "Price range",
            text: "Let's understand dynamics of your pricing",
            image: "./business-info/business-price.svg"
      },
      {
            id: 2,
            title: "Target audience",
            text: "Tell us about your user Demography",
            image: "/business-info/business-demography.svg"
      },
      {
            id: 3,
            title: "Store type",
            text: "Tell us what's your store type",
            image: "/business-info/business-store-type.svg"
      }
]

const Business = () => {
      
      return (
            <section className="business-container">

                  <div className="business-content-container">
                        <div className="business-highlight text-highlight">
                              FILTER ACCORDING TO YOUR
                        </div>

                        <div className="business-title">
                              Business
                              <span className="business-title-span">requirements</span>
                        </div>

                        <div className="business-synergy-btn btn btn-dark">
                              <Link className="btn-dark synergy-btn" to="Synergy">Checkout</Link>
                              <img className="business-icon-arrow" alt="arrow icon to redirect to synergy platform" src="arrow.svg" />
                        </div>
                  </div>

                  <div className="business-info-container">
                              <div className="business-info-cards">
                                    {
                                          data.map((item) => (
                                                <div className="business-info-card-item" key={item.id}>
                                                      <div className="business-info-card-image">
                                                            <img className="business-info-card-image" alt={`business info point ${item.id}`} src={item.image} />
                                                      </div>
                                                      <div className="business-info-card-body">
                                                            <div className="business-info-card-title">{item.title}</div>
                                                            <div className="business-info-card-text text-highlight">{item.text}</div>
                                                      </div>
                                                </div>
                                          ))
                                    }
                              </div>
                  </div>


            </section>
      )
}

export default Business 
