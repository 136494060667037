import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import  "./cookieConset.scss"  
const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };
const redirect_privacy_page =() =>{

  window.open("/Privacy", "_blank");


}
  return (
    <div className="cookie-consent">
    
    
  <div className="cookie_consent_headline" >This site uses cookies</div>

<div className='cookie_summary'>  By clicking <span className="thirdretail" style={{color : "#09a7b3"}}>"Accept all"</span> , you agree to the 
storing of cookies on your device to enhance 
site navigation analyze site usage, and assist  in our marketing efforts , view our <span  onClick={redirect_privacy_page} className="thirdretail" style={{color : "#09a7b3", cursor : "pointer" , textDecoration: "underline"}}>Privacy Policy </span>  
 for more information.</div>
 
      <div className="cookie_consent_button">
      <div  className = "cookie_accept_button" onClick={giveCookieConsent}>
        Accept
      </div>
      <div  className = "cookie_decline_button" onClick={giveCookieConsent}>
        Decline
      </div>
      </div>
    
    </div>
  );
};

export default CookieConsent;