import React from 'react'

const SuperAnalytics = () => {
  return (
    <div className='super_analytics_container'>
       <img  className = "Img_analytical" src = "./sup_analytics.jpg" alt ="" />
       <div className="glassy_rectangle">
        <div className="text_over_glassy">
        Launching soon
        </div>
  </div>
    </div>
  )
}

export default SuperAnalytics
