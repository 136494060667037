export const datak = [
    {   id : 1 ,
      question: "How can I rent a shelf for my products?",
      answer:
        "Simply navigate through our synergy mechanism, identify a suitable shelf for your products, and book it for your desired tenure.",
    },
    { id : 2 ,
      question: "I have a retail store; how can I list my shelf? ",
      answer:
        "Click on 'List Your Shelf' in the top navigation bar. Follow the step-by-step process to easily list your shelf and make it available for brands",
    },
    { id : 3 ,
      question: "I'm unsure about the rental price for my shelf. What should I do?",
      answer:
        "No worries! As you list your shelf and create your account, we offer a per-day rent estimation calculator to guide you. It's important to note that ThirdRetail doesn't control your rental pricing strategy; this tool is for estimation purposes.",
    },
    { id : 4 ,
      question: "Who manages the delivery of my product to the rental store?",
      answer:
        "Currently, in our early beta stage, we request brands to use their logistics solutions for delivering products to rented stores. We are actively working on aligning logistics in place. Your support in referring us to logistics partners is highly appreciated.",
    },
  ];