import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const General = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserEmail = () => {
        const curr_user_email = sessionStorage.getItem('EcomUserEmail');
       
    
      if (!curr_user_email) {
        toast.error('User email not found in session storage.');
        setLoading(false);
        return;
      }
      setUserEmail(curr_user_email);
      setLoading(false);
    };

    fetchUserEmail();
  }, []);

  const updateUser = async () => {
    if (!userEmail) {
      toast.error('Email must be provided to update user.');
      return;
    }

    if (!firstName.trim() || !lastName.trim()) {
      toast.error('First name and Last name are required.');
      return;
    }
 
    const query = `
    query{
  updateEcommUser(firstName: "${firstName}", lastName: "${lastName}", userEmail: "${userEmail}") 
   }
    `;

    try {
        const response = await axios.post(
          process.env.REACT_APP_GRAPHQL_ENDPOINT,
          { query },
          {
            headers: {
              'Content-Type': 'application/json', 
            }
          }
        );
  

      console.log(response.data);
      if (response.data.errors) {
        toast.error('Error updating user');
      } else {
        toast.success('Details updated. Please log in again to see changes.');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='profile_container'>
      <ToastContainer />
      <div className="profile_data_ecomm_headline_placeholder">
        <div className="profile_text_ecomm">Profile Photo</div>
        <div className="profile_placeholder_ecomm_img">
          <img src="./profile_ecomm.svg" alt="Profile" />
        </div>
      </div>
      <div className="profile_data_ecomm_headline_placeholder">
        <div className="profile_text_ecomm">First name</div>
        <div className="profile_placeholder_ecomm">
          <input
            className='placeholder_general_ecomm'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
      </div>
      <div className="profile_data_ecomm_headline_placeholder">
        <div className="profile_text_ecomm">Last name</div>
        <div className="profile_placeholder_ecomm">
          <input
            className='placeholder_general_ecomm'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="profile_data_ecomm_headline_placeholder">
        <div className="profile_text_ecomm">Email address</div>
        <div className="profile_placeholder_ecomm">
          <input
            className='placeholder_general_ecomm'
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            disabled // Disables input field to prevent email modification
          />
        </div>
      </div>
      <div className="edit_button_ecomm_general" onClick={updateUser}>
        <div className="edit_button_ecomm_icon">
          <img src="./edit.svg" alt="Edit" />
        </div>
        <div className="edit_button_text_ecomm">Edit</div>
      </div>
    </div>
  );
};

export default General;
