import React from 'react'
import Solve from '../../components/Solvingtheproblem/Solve'
import Brand from '../../components/Brand&physicalStore/Brand'
import Rent from '../../components/Rentashelf/Rent'
import Help from '../../components/Howwehelp/Help'
import Business from '../../components/BusinessRequirements/Business'
import Faqs  from '../../components/Faqs/Faqs'
import Slider from '../../components/Slider/Slider'
import Whatpeoplesay from '../../components/WhatPeopleSay/Whatpeoplesay'
import Blogs from '../Blogs/Blogs'
import Blog_section from '../../components/Blog_section/Blog_section'

const Home = () => {
  return (
    <main>
      <Rent />
      <Help/>
     <Slider/> 
      <Brand/>
      <Solve/>
      <Blog_section/>
      <Business/>
     <Whatpeoplesay/> 
     
       <Faqs/>

    </main>
  )
}

export default Home
