import React from 'react'
import { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import "./sdphead.scss"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

const SdpHead = ({ shelfData }) => {
	const { StoreName, SelectedState ,  shelfDetails,stroreImageUrl , shelfImage_url,selectCity,storeArea} = shelfData;

  const shelfImage = shelfDetails && shelfDetails.length > 0
  ? shelfDetails[0].shelfImage_url
  : null;
  const shelfimageurl = shelfImage ?  shelfImage.split(",") : [] ;
  const shelfimagemain = shelfimageurl.length > 0 ?  shelfimageurl[0] : null ;
  const imageUrls = stroreImageUrl ? stroreImageUrl.split(',') : [];

  // Use only the first image URL
  const mainImageUrl1 = imageUrls.length > 0 ? imageUrls[0] : null;
  const mainImageUrl2 = imageUrls.length > 0 ? imageUrls[1] : null;


      const selectedState = SelectedState;

      const ImageDisplay = ({ mainImageUrl1, shelfimagemain, mainImageUrl2 }) => {
        const [isMobile, setIsMobile] = useState(false);
      
        useEffect(() => {
          // Function to check if the screen size is mobile
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust this value as needed
          };
      
          // Set initial screen size
          handleResize();
      
          // Add event listener for resize
          window.addEventListener('resize', handleResize);
      
          // Cleanup the event listener on component unmount
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);
    
      }
      const images = [mainImageUrl1, shelfimagemain, mainImageUrl2];
     // Slider settings
     const settings = {
     // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
// Extract the address
const addressMatch = selectedState?.match(/"([^"]+)"Latitude/);
let address = addressMatch ? addressMatch[1].trim() : selectedState;

let mobileAddress = ''; // Declare mobileAddress in the outer scope

if (address) {
  // Remove the postcode (assuming UK postcode format) and "UK"
  mobileAddress = address.replace(/\b\w{1,2}\d{1,2}\s?\d\w{2}\b|UK/g, '').trim();
  
  // Remove the last comma if it exists
  mobileAddress = mobileAddress.replace(/,\s*$/, '').trim();
}



// Example usage



      

      // Extracting latitude and longitude
      const latitudeMatch = selectedState?.match(/Latitude : ([-+]?\d*\.\d+)/);
      const longitudeMatch = selectedState?.match(/Longitude : ([-+]?\d*\.\d+)/);

      // Check if matches were found
      const latitude = latitudeMatch ? parseFloat(latitudeMatch[1]) : null;
      const longitude = longitudeMatch ? parseFloat(longitudeMatch[1]) : null;

      // State to hold location details
      const [locationDetails, setLocationDetails] = useState({
        address: address || '', // Remove leading spaces
        latitude: latitude,
        longitude: longitude,
      });


        // Update locationDetails on mount
        useEffect(() => {
        // Check if both latitude and longitude are not null
        if (latitude !== null && longitude !== null) {
          setLocationDetails({
          address,
          latitude,
          longitude,
          });
        }
        }, [address, latitude, longitude]);

        const isMobile = window.innerWidth <= 1024;
  return (
    <div className='sdphead'>


    <div className="sdptext">

      		    	<div className="sdpnameWrapper ">
        
          				    	     <div className="sdpbordeauxGetaway">{StoreName}</div>
          					         <div className="sdplocation">
            						        <img className="sdpmappinIcon" alt="" src="/mappin.svg" />
              					          <div className="sdpbroadwayWest1">  {isMobile ? mobileAddress : address}</div>  
          				     	      </div>
      			                  </div> 

                <div className="sdptitle">
          					<div className="sdpshelvesInA1">Shelves in a top notch downtown market </div>
                    <div className="store_area_insdp">•{storeArea} sq.feet</div>
                </div>
    </div>



            <div className="sdpimage">
            {isMobile ? (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="sdpimg">
              <img className={`sdpimg${index + 1}`} src={image} alt="" />
            </div>
          ))}
        </Slider>
      ) : (
    <>
        <div className="sdpimg1">
          <img className='sdpimg1' src={mainImageUrl1} alt="" />
        </div>
        <div className="sdpimgsec">
          <div className="sdpimg2">
            <img className='sdpimg2' src={shelfimagemain} alt="" />
          </div>
          <div className="sdpimg3">
            <img className='sdpimg3' src={mainImageUrl2} alt="" />
          </div>
        </div>
        </>
      )}
            </div>



                  <div className="sdpbreadcrumbToDetach">

<div className="sdplinkhome">
<Link  className ="sdp_home_path"to = "/"  style={{ color: "inherit" }}>Home</Link></div>
<div className="sdpicon">
<img className="sdpRightarrowIcon" alt="" src="/Chevron_Right.svg" />
</div>
<div className="sdplinkcity"><Link to = "/Browseshops"  style={{ color: "inherit"}}>Listings in {selectCity}</Link></div>
<div className="sdpicon">
<img className="sdpRightarrowIcon" alt="" src="/Chevron_Right.svg" />
</div>
<div className="sdplink2">{StoreName}</div>
</div>

    </div>
  )
  }

export default SdpHead

