import React from 'react'
import { useState } from 'react'
import Browsestore from '../../components/Browsestore/Browsestore'
import Slpbottom from '../../components/Browsestore/Slpbottom'
import Slphead from '../../components/Browsestore/Slphead' 
import DatePickParent from '../../components/Browsestore/Calender/dateRangeParent'



const Browseshops = () => {
  const [formattedStartDate, setFormattedStartDate] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState(null);

  const handleDateChange = ({ startDate, endDate }) => {
    // Callback function to update the state with formatted dates
    setFormattedStartDate(startDate);
    setFormattedEndDate(endDate);
  };

  return (
    <div className='browseshop'>
   <Slphead>
     
        <DatePickParent getDateRangeState={handleDateChange} />
      </Slphead>

   <Browsestore formattedStartDate={formattedStartDate} formattedEndDate={formattedEndDate}  />
   <Slpbottom />
    </div>
 
  )
}

export default Browseshops
