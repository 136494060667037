// mutations/createUserMutation.js
export const CREATE_USER_MUTATION = `
mutation CreateUser($userEmail: String!, $userPassword: String!, $lastName: String!, $firstName: String! ,$role : String!) {
  createUser(userEmail: $userEmail, userPassword: $userPassword, lastName: $lastName, firstName: $firstName , role : $role) {
    user_id
  }
}
`;


