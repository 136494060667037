import React from 'react'
import "./aboutbuilding.scss"

const aboutbuilding = () => {
  return (
    <div  className='aboutbuilding'> 
       
    <div className="building">Building the future of retail</div>
    <div className="marketplace">intelligent saas-enabled marketplace</div> 


    </div>
  )
}

export default aboutbuilding
