import React from 'react'
import "./Dashboard_listing.scss"

const Store_Dashboard = () => {
  return (
    <div className='listing_dashboard'>
      <div className="dashboard_listing_container">


          <div className="listing_dashboard_headline_graph">
            <div className="listing_dashboard_headline">Total Bookings</div>
            <div className="listing_dashboard_graph"><img className="graph_dimm" src ="./t_bk_graph.svg" alt ="" /></div>
          </div>
          <div className="listing_dashboard_data">000</div>
          <div className="listing_dashboard_subheadline">Bookings</div>
      </div>


        <div className="dashboard_listing_container">

        
          <div className="listing_dashboard_headline_graph">
            <div className="listing_dashboard_headline">Footfall</div>
            <div className="listing_dashboard_graph"><img className="graph_dimm" src ="./footfall_graph.svg"  alt ="" /></div>
          </div>
          <div className="listing_dashboard_data">000</div>
          <div className="listing_dashboard_subheadline">Footfall</div>
      </div>
      <div className="dashboard_listing_container">

        
<div className="listing_dashboard_headline_graph">
  <div className="listing_dashboard_headline">Revenue </div>
  <div className="listing_dashboard_graph"><img className="graph_dimm" src  ="./rev_graph.svg" alt = "" /> </div>
</div>
<div className="listing_dashboard_data">£000</div>
<div className="listing_dashboard_subheadline">Rental Income</div>
</div>

<div className="dashboard_listing_container">

        
<div className="listing_dashboard_headline_graph">
  <div className="listing_dashboard_headline">Shelves Optimized</div>
  <div className="listing_dashboard_graph"></div>
</div>
<div className="listing_dashboard_data">00</div>
<div className="listing_dashboard_subheadline">Shelves</div>
</div>

   
    </div>
  )
}

export default Store_Dashboard

