import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Invite = () => {
    const [inviteEmails, setInviteEmails] = useState('');

    const handleInputChange = (event) => {
        setInviteEmails(event.target.value);
    };

    const handleInvite = async () => {
        const curr_user_email = sessionStorage.getItem('EcomUserEmail');
     
        if (!curr_user_email) {
          toast.error('User email not found in session storage.');
          return;
        }

        const mutation = `
          mutation {
            invitePeople(invitesEmailIds: "${inviteEmails}", email: "${curr_user_email}") 
          }
        `;
    
        try {
          const response = await axios.post(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
            query: mutation,
          });
    
          const result = response.data.data.invitePeople;
          if (result) {
            toast.success('Invitations sent successfully!');
          } else {
            toast.error('Error sending invitations.');
          }
        } catch (error) {
          toast.error(`Request failed: ${error.message}`);
        }
    };
    
    const curr_user_email = sessionStorage.getItem('EcomUserEmail');

    return (
        <div className='invite_ecomm_container'>
            <div className="invite_headline_ecomm">
                <div className="invite__main_headline_ecomm">Invite Your Friends</div>
                <div className="invite_subheadline_ecomm">You can invite your team members from here.</div>
            </div>
            <div className="invite_email_input_ecomm">
                <div className="invite_email_input_ecomm_placeholder">
                    <input
                        className='placeholder_general_ecomm_invite'
                        type="text"
                        value={inviteEmails}
                        onChange={handleInputChange}
                        placeholder="Enter email addresses separated by commas"
                    />
                </div>
                <div className="invite_email_input_ecomm_send_invite" onClick={handleInvite}>Send invite</div>
                <ToastContainer />
            </div>
            <div className="invite_user_email_ecomm">
                <div className='table_invites_headline'>
                    <div className="table_invites_list_container">
                        <div className='referral_invites_headline'>Referral email</div>
                        <div className="name_headline_invites">Name</div>
                    </div>
                    <div className='table_invites_data'>
                        <div className="table_invite_data_user_mail_img">
                            <div className="invite_user_ecomm_img">
                                <img src="./invite_user.svg" alt="" />
                            </div>
                            <div className="owner_email_invite_ecomm">{curr_user_email}</div> 
                        </div>
                        <div className='invite_role'>Admin</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invite;
