import React from 'react'
import "./aboutoptimise.scss"
import { Link } from 'react-router-dom';
const aboutoptimise = () => {
          return (
            <div className="optimisesection">


<div className="optimise_background">

    <img className="optimise_background_img" src="/optimise_bg.png" alt="" />


    <div className="optimise_text_button">
        <div className="optimise_text">
            <div className="optimise_headline">LIST WITH US</div>
            <div className="optimise_desc">Optimise your in-store revenue</div>
        </div>
        <div className="list_with_us_button">
            <Link to="/listyourshelf" className="list_with_us_button_link"> List Now ={`>`} </Link>
        </div>
    </div>

    <div className="about_optimise_content_img">
    <div className="about_optimise_side_images">
      <div> <img  className="optimise_side_img_1" src ="/optimise_aboutus_img.svg" alt ="" /></div>
 
   </div>
                 
</div>
</div>
      
            </div>);
}
    

export default aboutoptimise
