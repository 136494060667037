import React from 'react'
import "./aboutomnichannel.scss"
import { Link } from 'react-router-dom';
const aboutomnichannel = () => {

               return(

                          <div className="omnichannelgroupParent">

                          <div className="omnicahnnelimg">
                          <img className="omnicahnnelimg" src ="/omnichannel.png"  alt = " " /> 
                          </div>

                          <div className="omnichannelframeGroup">


                       

                          <div className="omnichannelwereBuilding">
                            <span style={{color :"black"}}>We're</span> building</div>

                     
                          <div className="omnichannelaffordableOmnichannelExperie">Affordable-omnichannel experience for your customers</div>
                          <div className="omnichannelbutBeforeThat1">but before that lets understand your product & business </div>
                       
                          <div className="omnichannelbutton">
                          <Link className="omnichannellabel"  to="/Synergy">Get started</Link>
                          <div className="omnichannelicon1">
                          <img className="omnichannelicon2" alt="" src="omniarrow.svg" />
                          </div>
                          </div>

                          
                          </div>
                          </div>

               ) }

export default aboutomnichannel
