import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';

export class Mapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMarker: null,
      selectedPlace: null,
      showingInfoWindow: false,
    };
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props.name,
      showingInfoWindow: true,
    });
  };

  renderMarkers = () => {
    const { latLongArray, storeName } = this.props;

    if (!latLongArray || !Array.isArray(latLongArray) || latLongArray.length === 0) {
      return null;
    }

    return latLongArray.map((latLong, index) => {
      if (!latLong || typeof latLong.latitude !== 'number' || typeof latLong.longitude !== 'number') {
        return null;
      }

      return (
        <Marker
          key={index}
          position={{ lat: latLong.latitude, lng: latLong.longitude }}
          onClick={this.onMarkerClick}
          name={storeName[index]} // Assuming storeName array corresponds to latLongArray
        />
      );
    });
  };

  render() {
    const mapStyles = {
      width: '540px',
      height: '1200px',
      borderRadius: '10px',
      overflow: 'hidden',
    };

    const containerStyle = {
      position: 'relative',
      width: '540px',
      borderRadius: '10px',
    };
    const londonDefaultLocation = { lat: 51.509865, lng: -0.118092 };
    return (
      <Map
        containerStyle={containerStyle}
        google={this.props.google}
        zoom={13}
        style={mapStyles}
        initialCenter={londonDefaultLocation} // Set the default location here
      >
        {this.renderMarkers()}

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={() => this.setState({ showingInfoWindow: false })}
        >
          <div>
            <h3>{this.state.selectedPlace}</h3>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyByI1MXrBW50io37CBScK-6Qk4b8WksZZc'),
})(Mapp);
