
export const data = [
    {
        id: 1,
        name: "Tariko M.",
        company: "N K Convenient Store",
        review: "We're excited at N K Convenient Store about ThirdRetail launching soon. It sounds cool – helping us make more money from our shelves. Can't wait to try it out!",
        img: "review1.png"
    },
    {
        id: 2,
        name: "Amit sood",
        company: "Jasmine News",
        review: "We think ThirdRetail's idea of renting shelves is interesting at Jasmine News. It's like businesses teaming up to help stores earn more money. We're curious to see how it works when ThirdRetail is ready.",
        img: "review2.png"
    },
    {
        id: 3,
        name: "Kashyap",
        company: "The Shoreditch store",
        review: "We're looking forward to ThirdRetail launching. The idea of making extra money by renting shelf space sounds good, especially during these tough times. Hope it brings positive changes for businesses like ours.",
        img: "review3.png"
    },
    {
        id: 4,
        name: "Tariko M.",
        company: "N K Convenient Store",
        review: "We're excited at N K Convenient Store about ThirdRetail launching soon. It sounds cool – helping us make more money from our shelves. Can't wait to try it out!",
        img: "review1.png"
    },
    {
        id: 5,
        name: "Amit sood",
        company: "Jasmine News",
        review: "We think ThirdRetail's idea of renting shelves is interesting at Jasmine News. It's like businesses teaming up to help stores earn more money. We're curious to see how it works when ThirdRetail is ready.",
        img: "review2.png"
    },
    {
        id: 6,
        name: "Kashyap",
        company: "The Shoreditch store",
        review: "We're looking forward to ThirdRetail launching. The idea of making extra money by renting shelf space sounds good, especially during these tough times. Hope it brings positive changes for businesses like ours.",
        img: "review3.png"
    }
]