import "./MobileHero.css";
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const MobileHero = () => {
      const navigate = useNavigate();

      const handleClick = () => {
            window.open('/request early access', '_blank'); // Open the form in a new tab
        };
    return (
      <section className="rent-text-mobile-container">

                      <div className="mobile-beta-message">
                            <div className="rent-mobile-beta-message">
                                  <span className="rent-mobile-new-notifier">
                                        NEW
                                  </span>
                                  Marketplace Private Beta Launched
                            </div>
                      </div>

                      <div className="rent-mobile-text-heading">
                            
                            <div className="mobile-text-heading-first">
                                  Rent a 
                                  <div className="mobile-text-highlight-shelf">
                                        <div className="rent-mobile-shelf-text">
                                              shelf
                                        </div>
                                        <div className="rent-mobile-shelf-smile-image">
                                              <img src="./hero-section/shelf-smile.png" alt="shelf"/>
                                        </div>

                                  </div>
                                  for
                            </div>

                            <div className="mobile-text-subheading">
                                  your business
                            </div>
                      </div>

                      <div className="rent-mobile-text-subheading">
                      Get your products in front of customers in the aisles of their favorite stores. It's like a pop-up shop ,but
way more convenient.
                      </div>
                      <div className="rent-btn-search">
                              <div className="rent-synergy-btn">
                                    <Link to="Synergy" className="rent-button-links">
                                          Get started
                                    </Link>
                                    <div className="user_pop_wishlist" onClick={handleClick}>
                                   Request early access
                                    </div>


                              </div>
                             
                              
                  
                           
                      </div>
      </section>
  )
}