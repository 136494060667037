// mutations/signInUserMutation.js
export const SIGN_IN_USER_MUTATION = `
  mutation SignInUser($userEmail: String!, $userPassword: String!) {
    signInUser(userEmail: $userEmail, userPassword: $userPassword) {
      token
      ecommUser{
        EcomUserEmail
        FirstName
        lastName
        ecomUser_id
        EcomUserRole
      }
      users{
       UserEmail
       UserRole
       FirstName
       lastName
       user_id
       
       registerStores{
        storeId
      }
     }
 
    }
  }
`;


