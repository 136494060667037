import React, { useState } from 'react'
import {datak} from  './data'
import MyAccordino from './MyAccordino';


const Accordino = () => {
  
  const [data] = useState(datak) ;
  return (
    <div>
      {
        data.map((curElem)=> {
          const {id} = curElem ;
          return <MyAccordino key = {id} {...curElem}/> ;
        })
      }  
    </div>
  )
}

export default Accordino
