import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
// import  "./Rent.scss"
import  "./Rent.css"
import "./Synergy"
import { Hero } from './Hero';
import { ImagePlayground } from './ImagePlayground';
import { MobileHero } from './MobileHero'; 

const Rent = () => {

      const [screenWidth, setScreenWidth] = useState(window.innerWidth);

      const handleResize = () => {
            setScreenWidth(window.innerWidth);
          };
        
      useEffect(() => {
            window.addEventListener('resize', handleResize);
            return () => {
                  window.removeEventListener('resize', handleResize);
            };
      }, [])

 const backgroundImage =  "./hero-section/hero_bg1.png" 
      
      return (
            <section className="rent-container">

                  { screenWidth > 768 ?
                        <>
                              <div className="rent-shelf-bg">
                                    <img className='hero_img_bg' src={`${backgroundImage}`} alt="hero section image"/>
                              </div>
                              
                              <Hero/>
                        </>
                  : 
                        <>
                              <MobileHero/>
                        </>
                  
                  }

                  
                  
<div className="image_playgroud" > { screenWidth > 768 ? <ImagePlayground/> : null }</div>
                 
            </section>
      );
}

export default Rent
