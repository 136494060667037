import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Synergydatatile3 = () => {
  const optionsage = ['Infant Products', '4-15YO', '16-24YO', '25-35YO', '35-45YO'  ,'65YO+'];

  const [selectedOptionsage, setSelectedOptionsage] = useState([]);
  const handleCheckboxChangeage = (optionage) => {
    if (selectedOptionsage.includes(optionage)) {
      setSelectedOptionsage(selectedOptionsage.filter((selected) => selected !== optionage));
    } else {
      setSelectedOptionsage([...selectedOptionsage, optionage]);
    }}
    sessionStorage.setItem('selectedOptionsage', selectedOptionsage) ;
   //  console.log(selectedOptionsage)
  return (
    <div className='synergydatatile1'>
        <div className="synergydataleft">

        
<div className="logo_summary">
<div className="synergy_icon_left_top">
<Link to="/">
<img src="/synergy_logo.svg" alt="Synergy Logo" />
</Link>
</div>
<div className="synergy_text">Create the most affordable omnichannel experience for your product.</div>
</div>
<div className="synergy_insight_section">
<div className="synergy_insight_data">
<div className="side_img_synergy_insight">
            <div className="synergy_insight_percentage">57%</div>
            <div className="img_animation_section">
         <img className="animated-image_insight top-right"  src="/icon_insight_1.svg" alt=""  />
         <img src="/icon_insight_2" alt="" className="animated-image_insight top-right" />
         <img src="/icon_insight_1" alt="" className="animated-image_insight top-right" />
         </div>
        </div> 
  <div className="synergy_insight_data_text">of consumers say they’re likely to browse online and buy in-store. (Credits : Shopify)</div>
  <div className="synergy_insight_controller">
    <div className="synergy_insight_controller_left"></div>
    <div className="synergy_insight_progressbar"><img src ="/progessbar_3.svg" alt ="" /></div>
    <div className="synergy_insight_controller_right"></div>
    </div>
  </div>

</div>



</div>
     
      <div className="synergydataright">
      <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergy"></div>
          <div className="line1synergy"></div>


        </div>
        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">What is the ideal age of your Target Audience ?</div>
     
      <div className="optionsynergy">
     
    
     {optionsage.map((optionage, index) => (
        <label
          key={index}
          className={`checkbox13-container ${selectedOptionsage.includes(optionage) ? 'selected' : ''}`}
        >
          <input
          id = " synergy3"
            type="checkbox"
            checked={selectedOptionsage.includes(optionage)}
            onChange={() => handleCheckboxChangeage(optionage)}
          />
          {optionage}
        </label>
      ))}
    
    </div>

     </div>
    </div>
  )
}

export default Synergydatatile3
