import React, { useEffect, useState } from 'react';
import './Form1.scss';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form1 = (props) => {
    // Button handling
    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

    const handleSaveClick = async () => {
        try {
            setIsSaveButtonClicked(true);
            toast.success('Data saved successfully!');
            setIsNextButtonDisabled(false);
        } catch (error) {
            console.error('Save error:', error);
        }
    };

    const handleNextClick = () => {
        if (!isSaveButtonClicked) {
            toast.error('Please click "Save" before proceeding to "Next".');
        } else {
            toast.info('Proceeding to the next step.');
        }
    };

    // GraphQL Endpoint
    const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

    // Form data and state
    const [formData, setFormData] = useState({
        storeName: '',
        storeArea: '',
        userId: localStorage.getItem('authToken') || '',
        selectCity: '',
        selectCountry: '',
        postalCode: '',
        storeAddress: '',
        storeDescription : ''
    });

    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {
        if (formData.postalCode.length > 0) {
            fetchLocationData(formData.postalCode);
        }
    }, [formData.postalCode]);

    const fetchLocationData = async (postalCode) => {
        try {
            const response = await axios.post('https://api.postcodes.io/postcodes', {
                postcodes: [postalCode]
            });

            const result = response.data.result[0].result;
            if (result) {
                setSelectedCountry(result.country);
                setSelectedCity(result.parliamentary_constituency);

                setFormData({
                    ...formData,
                    selectCountry: result.country,
                    selectCity: result.parliamentary_constituency
                });
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        const handleStorageChange = () => {
            const authToken = localStorage.getItem('authToken');
            setFormData((prevFormData) => ({ ...prevFormData, userId: authToken || '' }));
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userIdAsLong = parseInt(formData.userId, 10);
            const response = await axios.post(graphqlEndpoint, {
                query: `
                  mutation RegisterStore(
                    $storeName: String!,
                    $storeArea: Long!,
                    $selectCity: String!,
                    $selectCountry: String!,
                    $postalCode: String!,
                    $storeAddress: String!,
                      $storeDescription : String! ,
                    $userId: Long!,
                  
                  ) {
                    registerStore(
                      storeName: $storeName,
                      storeArea: $storeArea,
                      userId: $userId,
                      selectCity: $selectCity,
                      selectCountry: $selectCountry,
                      postalCode: $postalCode,
                      storeAddress: $storeAddress,
                      storeDescription : $storeDescription,
                    )
                  }
                `,
                variables: {
                    storeName: formData.storeName,
                    storeArea: formData.storeArea,
                    selectCity: formData.selectCity,
                    selectCountry: formData.selectCountry,
                    postalCode: formData.postalCode,
                    storeAddress: formData.storeAddress,
                    storeDescription : formData.storeDescription,
                    userId: userIdAsLong,
                },
            });

            const registerStore = response.data.data.registerStore;
            localStorage.setItem('register_store', registerStore);
            props.setStoreId(registerStore);
            setFormData({
                storeName: '',
                storeArea: '',
                userId: props.token || '',
                selectCity: '',
                selectCountry: '',
                postalCode: '',
                storeAddress: '',
                storeDescription : '',
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="form1">
            <div className="form_page">
                        <div className="fullfirstblock">

                        <div className="form1firstblock">
                        <div className="storeinfotitle">Store Information</div>
                        <div className="businesstellUsAbout1">
                            Tell us about your Store. These specifics will help us figure out the best clients for you.
                        </div>
                    </div>

                    <div className="form">
                        <form onSubmit={handleSubmit}>
                            <div className="textField">

                                <div className="labelWrapper">
                                    <div className="label">Store name</div>
                                    </div>
                                <input
                                     type="text"
                                    id="store_name_input"
                                    placeholder="Enter store name"
                                    value={formData.storeName}
                                    onChange={(e) => setFormData({ ...formData, storeName: e.target.value })}
                                />
                               
                            </div>


							<div className="areaandcode">

                                <div className="selectField_postalcode">
                                    <div className="labelWrapper">
                                        <div className="label">Postal Code</div>
                                    </div>
                                   
                                    <input
                                       type="text"
                                     className="postcode_input"
									placeholder='EC1V 2NX'
                                        value={formData.postalCode}
                                        onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                                      />
                                     

                                </div>
								<div className="selectField_area">
                                    <div className="labelWrapper">
                                        <div className="label">Store Area(sq .ft)</div>
                                    </div>
                                    <input
                                       type="text"
									   placeholder='Total store area'
                                        className="store_area_input"
                                        value={formData.storeArea}
                                        onChange={(e) => setFormData({ ...formData, storeArea: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="textArea">
                                <div className="labelWrapper">
                                    <div className="label">Store address</div>
                                </div>
                                <input
                                    type="text"
                                    id="input_address"
									placeholder='Address'
                                    value={formData.storeAddress}
                                    onChange={(e) => setFormData({ ...formData, storeAddress: e.target.value })}
                                />
                            </div>

                            <div className="textArea">
                                <div className="labelWrapper">
                                    <div className="label">Store Description</div>
                                </div>
                                <textarea
     
        className="textarea_input_store_description"
        placeholder="Store Description"
        value={formData.storeDescription}
        onChange={(e) => setFormData({ ...formData, storeDescription: e.target.value })}
        rows={5}  // You can adjust the number of visible lines
    />
                            </div>


							<div className="selectFieldParent">
                                <div className="selectField_select_country">
                                    <div className="labelWrapper">
                                        <div className="label">Select Country</div>
                                    </div>
                                    <select
                                        value={formData.selectCountry}
                                        onChange={(e) => {
                                            setSelectedCountry(e.target.value);
                                            setFormData({ ...formData, selectCountry: e.target.value });
                                        }}
                                        className="input_country"
                                    >
                                        <option value=""> Country</option>
                                        <option value={selectedCountry}>{selectedCountry}</option>
                                    </select>
                                </div>

                                <div className="selectField_select_city">
                                    <div className="labelWrapper">
                                        <div className="label">Select City</div>
                                    </div>
                                    <select
                                        value={formData.selectCity}
                                        onChange={(e) => {
                                            setSelectedCity(e.target.value);
                                            setFormData({ ...formData, selectCity: e.target.value });
                                        }}
                                        className="input_city"
                                    >
                                        <option value="">City</option>
                                        <option value={selectedCity}>{selectedCity}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form_1_buttonContainer">
    <button type="submit" className="form_1_buttonSave" onClick={handleSaveClick}>
        Save
    </button>
    <Link
        className={`form_1_buttonNext ${isNextButtonDisabled ? 'disabled' : ''}`}
        to={isSaveButtonClicked ? 'Form12' : ''}
        onClick={handleNextClick}
        style={{ textDecoration: 'none' }}
    >
        Next
    </Link>
</div>

                        </form>
                    </div>
                </div>
                </div>
           
        </div>
    );
};

export default Form1;
