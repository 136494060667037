import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation } from 'swiper/core';
import './Slider.scss'; // Assuming Slider.css contains your custom styles
import { data } from './sliderData';


const Slider = () => {
  return (
    <div className='slider_container'>

         
          <div className="slider_section">

            

            <div className="slider_section_1">

            <div className="slide_1_help_brand">
              <div className="img_slide_help_brand"><img className='slide_1_img' src ="/HandCoins.svg" alt="" /></div>
              <div className="headline_slide_help_brand">Save on ads spend</div>
              <div className="desc_slide_help_brand">Cut down on advertising costs while achieving effective customer acquisition through our budget-friendly offline shelf network</div>
            </div>
            <div className="slide_2_help_brand"> 
              <div className="img_slide_help_brand"><img className='slide_2_img' src ="/ChartPieSlice.svg" alt="" /></div>
              <div className="headline_slide_help_brand">Expand smoothly, sell more</div>
              <div className="desc_slide_help_brand">Fuel your product launch or expansion with a hassle-free new sales channel – access our recommended shelves for tailored growth.</div>
              </div>

              </div>

  <div className="slider_section_2">
            <div className="slide_3_help_brand"> 
              <div className="img_slide_help_brand"><img className='slide_3_img' src ="/UsersFour.svg" alt="" /></div>
              <div className="headline_slide_help_brand">Bring your product closer</div>
              <div className="desc_slide_help_brand">Put your product where it matters – local shelves. Bridge the gap between online presence for a richer customer experience.</div>
              </div>
            <div className="slide_4_help_brand">
               <div className="img_slide_help_brand"><img className='slide_4_img' src ="/ChartLine.svg" alt="" /></div>
              <div className="headline_slide_help_brand">Impactful operations</div>
              <div className="desc_slide_help_brand">Remove your cross-channel blind spot, while we predict, generate AI-driven insights, and reduce wastage for you .</div>
              </div>
              </div>
  
          </div>

       
    </div>
  )
}

export default Slider

